import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const RealTimeComment = () => {
    const [comments, setComments] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        let socketUrl;
        if (process.env.NODE_ENV === "production") {
            socketUrl = "wss://api.시즌프리.com:8000/comments";
        } else {
            socketUrl = "http://localhost:8000/comments";
        }

        const socket = io(socketUrl, {
            withCredentials: true,
            transports: ['websocket'],
            reconnection: true,
        });

        socket.on('connect', () => {
            console.log('Connected to WebSocket');
            socket.emit('requestLatestPostsByComment');
        });

        socket.on('receiveLatestComments', (latestComments) => {
            setComments(latestComments);
        });

        socket.on('error', (errorMessage) => {
            console.error('Error:', errorMessage);
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from WebSocket');
        });

        return () => {
            socket.off('receiveLatestComments');
            socket.close();
        };
    }, []);

    const handleCommentClick = (category, postId) => {
        navigate(`/${category.toLowerCase()}/${postId}`);
    };

    return (
        <RealtimeCommentBox>
            <Header>실시간 댓글</Header>
            <CommentList>
                {comments.map((comment) => (
                    <CommentRow key={comment.id} onClick={() => handleCommentClick(comment.post.category, comment.post.id)}>
                        <CommentNien>ㄴ</CommentNien>
                        <CommentContent>{comment.content}</CommentContent>
                        <New src={"new.png"} alt="New Icon" />
                        <Nickname>{comment.user.nickname}</Nickname>
                    </CommentRow>
                ))}
            </CommentList>
            <Footer>
                댓글 작성 시 50포인트 지급!<br />
                [ 하루 6개 댓글 제한 ]
            </Footer>
        </RealtimeCommentBox>
    );
};

const RealtimeCommentBox = styled.div`
    width: 350px;
    height: 500px;
    margin-top: 5px;
    border: #23241f 1px solid;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const Header = styled.div`
    border-bottom: #23241f 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 15px;
    margin-bottom: 10px;
`;

const Footer = styled.div`
    border-top: #23241f 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 18px;
    text-align: center;
    margin-top: 15px;
`;

const CommentList = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: 20px;
`;

const CommentRow = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const CommentNien = styled.div`
    font-size: 22px;
`;

const CommentContent = styled.div`
    width: 200px;
    font-size: 15px;
    margin-top: 7px;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const New = styled.img`
    width: 25px;
    height: 25px;
    margin-top: 7px;
`;

const Nickname = styled.div`
    margin-top: 7px;
    margin-left: 10px;
`;

export default RealTimeComment;

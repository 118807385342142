import React, { useRef, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {JoinApi, ProfileImageUrl, SendEmailApi} from "../../apis/User";
import { FaPlus, FaUser } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

const Join = ({onClose = () => {}}) => {
    const [sendEmail, setSendEmail] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const inputRef = useRef(null);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        if (data.password !== data.confirmPassword) {
            alert('비밀번호가 일치하지 않습니다!');
            return;
        }

        const formData = new FormData();
        formData.append('userId', data.id);
        formData.append('password', data.password);
        formData.append('nickname', data.nickname);
        formData.append('email', data.email);
        formData.append('otp', data.otp);
        formData.append('profileImage', profileImageUrl);

        try {
            // 회원가입 API 호출 > onClose Function 제대로 넘기기!
            await JoinApi(formData, onClose);
            // onClose();
        } catch (error) {
            console.error('회원가입 중 오류 발생:', error);
        }
    };

    const handleSendEmail = async (data) => {
        setSendEmail(true);
        await SendEmailApi(data.email, setVerificationSent, setSendEmail);
    };

    const onUploadImageButtonClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, []);

    const handleFileChange = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await ProfileImageUrl(formData);

                if (response.status !== 201) {
                    throw new Error('서버로부터 응답을 받는데 실패했습니다.');
                }

                const result = response.data; // response.json() 대신 response.data 사용
                setProfileImageUrl(result.imageUrl);
            } catch (error) {
                console.error('파일 업로드 오류:', error);
            }
        } else {
            console.log('선택된 파일 없음');
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ProfileIconRow>
                {profileImageUrl ? (
                    <ProfileIcon hasImg={!!profileImageUrl}>
                        <FaXmark
                            className="imgHandler clear"
                            onClick={() => {
                                setProfileImageUrl('');
                            }}
                        />
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                overflow: "hidden",
                                borderRadius: "50%",
                            }}
                        >
                            <img src={profileImageUrl} alt="Profile" />
                        </div>
                    </ProfileIcon>
                ) : (
                    <ProfileIcon>
                        <FaPlus
                            className="imgHandler add"
                            onClick={onUploadImageButtonClick}
                        />
                        <FaUser className="user" />
                    </ProfileIcon>
                )}
                <input
                    id="profileImageInput"
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
            </ProfileIconRow>
            <InputComponent
                {...register("id", {
                    required: "ID를 입력해주세요.",
                    pattern: {
                        value: /^[A-Za-z0-9]{4,12}$/,
                        message: "ID는 4~12글자의 영문 및 숫자만 가능합니다.",
                    },
                })}
                placeholder="아이디를 입력해주세요.(영문 숫자조합 4자이상 ~ 12자 미만)"
                disabled={sendEmail || verificationSent}
            />
            {errors.id && (
                <div style={{ color: "red", marginLeft: "10px" }}>
                    {errors.id.message}
                </div>
            )}

            <InputComponent
                {...register("password", {
                    required: "비밀번호를 입력해주세요.",
                    minLength: {
                        value: 6,
                        message: "비밀번호는 최소 6자 이상입니다.",
                    },
                    maxLength: {
                        value: 20,
                        message: "비밀번호는 최대 20자 이하입니다.",
                    },
                })}
                type="password"
                placeholder="비밀번호를 입력해주세요.(최소 6자 ~ 최대 20자)"
                disabled={sendEmail || verificationSent}
            />
            {errors.password && (
                <div style={{ color: "red", marginLeft: "10px" }}>
                    {errors.password.message}
                </div>
            )}

            <InputComponent
                {...register("confirmPassword", { required: true })}
                type="password"
                placeholder="비밀번호를 한번 더 입력 해 주세요."
                disabled={sendEmail || verificationSent}
            />
            {errors.confirmPassword && (
                <div style={{ color: "red", marginLeft: "10px" }}>
                    비밀번호 확인을 입력해주세요.
                </div>
            )}

            <InputComponent
                {...register("nickname", { required: true })}
                placeholder="닉네임을 입력 해 주세요."
                disabled={sendEmail || verificationSent}
            />
            {errors.nickname && (
                <div style={{ color: "red", marginLeft: "10px" }}>
                    닉네임을 입력해주세요.
                </div>
            )}

            <InputComponent
                {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                type="email"
                placeholder="이메일 주소를 입력 해 주세요."
                disabled={sendEmail || verificationSent}
            />
            {errors.email && (
                <div style={{ color: "red", marginLeft: "10px" }}>
                    유효한 이메일 주소를 입력해주세요.
                </div>
            )}

            {verificationSent && (
                <>
                    <InputComponent
                        {...register("otp", { required: "인증번호를 입력해주세요." })}
                        placeholder="인증번호를 입력하세요."
                    />
                    {errors.code && <ErrorText>{errors.code.message}</ErrorText>}
                </>
            )}

            {verificationSent ? (
                <SubmitButton type="submit">회원가입</SubmitButton>
            ) : (
                <SubmitButton type="button" onClick={handleSubmit(handleSendEmail)}>
                    인증번호 전송
                </SubmitButton>
            )}
        </form>
    );
};

const ProfileIconRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ProfileIcon = styled.div`
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    padding: ${(props) => (props.hasImg ? "0" : "15px")};
    background-color: gray;
    border-radius: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    svg.user {
        width: 100%;
        height: auto;
        color: white;
    }
    svg.imgHandler {
        position: absolute;
        top: 0;
        right: -5px;
        color: white;
        border-radius: 50%;
        width: 30%;
        height: 30%;
        padding: 1px;
        z-index: 1;
        cursor: pointer;
        &.add {
            background-color: #70e07f;
        }
        &.clear {
            background-color: #f55151;
        }
    }
`;

const InputComponent = styled.input`
    width: 95%;
    margin: 10px;
    padding: 8px;
`;

const SubmitButton = styled.button`
    width: 90%;
    padding: 10px;
    margin: 20px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 5px;
`;

const ErrorText = styled.div`
    color: red;
    font-size: 14px;
    padding-left: 10px;
`;

export default Join;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPen } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { GetPost } from "../../apis/BBS";
import { useAuthState } from "../../context/Auth";
import Swal from "sweetalert2";
import axiosInstance from "../../apis/Axios";
import TableRow from "../../components/common/TableRow";
import renderPagination from "../../components/RenderPagination";

const ListPage = () => {
    const { gameKey } = useParams();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const rowsPerPage = 15;

    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated } = useAuthState();

    const [selectedOption, setSelectedOption] = useState('리니지');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const path = location.pathname;
        switch (path) {
            case '/lineage':
                setSelectedOption('리니지');
                break;
            case '/lineage_2':
                setSelectedOption('리니지2');
                break;
            case '/remaster':
                setSelectedOption('리마스터');
                break;
            default:
                setSelectedOption('리니지');
        }
    }, [location]);

    const handleOptionClick = (option, path) => {
        setSelectedOption(option);
        navigate(path);
    };

    const queryParams = new URLSearchParams(location.search);
    const currentPage = parseInt(queryParams.get('page')) || 1;

    const fetchData = async () => {
        setLoading(true);
        try {
            const { posts, totalRows } = await GetPost(gameKey, currentPage);
            setPosts(posts);
            setTotalRows(totalRows);
        } catch (error) {
            console.error('Error fetching data:', error);
            setPosts([]);
            setTotalRows(0);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [gameKey, currentPage]);

    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const maxPageButtons = 10;
    const startPage = Math.floor((currentPage - 1) / maxPageButtons) * maxPageButtons + 1;
    const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    const handleClick = (pageNumber) => {
        navigate(`?page=${pageNumber}`);
    };

    const handlePrevPageGroup = () => {
        if (startPage > 1) {
            navigate(`?page=${startPage - 1}`);
        }
    };

    const handleNextPageGroup = () => {
        if (endPage < totalPages) {
            navigate(`?page=${endPage + 1}`);
        }
    };

    const handleFirstPage = () => {
        navigate(`?page=1`);
    };

    const handleLastPage = () => {
        navigate(`?page=${totalPages}`);
    };

    const renderRows = () => {
        return posts.map((post, index) => (
            <TableRow
                key={post.id}
                index={index + 1}
                id={post.id}
                title={post.title}
                nickname={post.userNickname}
                writeDate={post.writeDate}
                postColor={post.postColor}
                watch={post.watch}
                gameKey={gameKey}
                postType={post.postType}
                onPostUpdated={fetchData}
            />
        ));
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = async () => {
        if (!searchTerm.trim()) {
            return;
        }

        setLoading(true);
        try {
            const response = await axiosInstance.get('/bbs/search', {
                params: { game: gameKey, keyword: searchTerm, page: currentPage, rowsPerPage }
            });
            setPosts(response.data.posts);
            setTotalRows(response.data.total);
        } catch (error) {
            console.error('Error searching data:', error);
            setPosts([]);
            setTotalRows(0);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            await handleSearchSubmit();
        }
    };

    const writePost = () => {
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'warning',
                title: '먼저 로그인 해 주세요',
                text: '글 작성을 하시려면 로그인 해 주세요.',
            });
            return;
        }

        navigate(`/${gameKey}/write`);
    };

    const validGameKeys = ['lineage', 'lineage_2', 'remaster'];

    return (
        <ListBox>
            <BBSHeader>홍보게시판</BBSHeader>
            {validGameKeys.includes(gameKey) && (
                <OptionRow>
                    <OptionButton
                        isSelected={selectedOption === '리니지'}
                        onClick={() => handleOptionClick('전체', '/lineage')}
                    >
                        리니지
                    </OptionButton>
                    <OptionButton
                        isSelected={selectedOption === '리니지2'}
                        onClick={() => handleOptionClick('리니지2', '/lineage_2')}
                    >
                        리니지2
                    </OptionButton>
                    <OptionButton
                        isSelected={selectedOption === '리마스터'}
                        onClick={() => handleOptionClick('리마스터', '/remaster')}
                    >
                        리마스터
                    </OptionButton>
                </OptionRow>
            )}

            <DivideHr />

            <FlexRow>
                <SearchAndWriteRow>
                    <SearchBox>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                        <SearchInput
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyPress}
                            placeholder="검색어를 입력하세요."
                        />
                    </SearchBox>
                    <WriteButton onClick={writePost}>
                        <FontAwesomeIcon icon={faPen} /> 쓰기
                    </WriteButton>
                </SearchAndWriteRow>
            </FlexRow>
            <DivideHrBold />

            <DataTable>
                <thead>
                <tr>
                    <Th>NO</Th>
                    <ThThumb>추천</ThThumb>
                    <ThTitle>제목</ThTitle>
                    <Th>닉네임</Th>
                    <Th>작성일</Th>
                    <Th>조회수</Th>
                </tr>
                </thead>
                <tbody>
                {loading ? (
                    <tr>
                        <Td colSpan={6}>Loading...</Td>
                    </tr>
                ) : (
                    renderRows()
                )}
                </tbody>
            </DataTable>

            {renderPagination({
                startPage,
                endPage,
                currentPage,
                handleClick,
                handleFirstPage,
                handlePrevPageGroup,
                handleNextPageGroup,
                handleLastPage,
                totalPages
            })}

        </ListBox>
    );
};

const ListBox = styled.div`
    width: 80%;
    margin-bottom: 80px;
`;

const BBSHeader = styled.span`
    width: 100%;
    font-size: 40px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
    text-align: center;
`;

const OptionRow = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const OptionButton = styled.button`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: black solid 2px;
    background-color: ${(props) => (props.isSelected ? 'black' : 'white')};
    color: ${(props) => (props.isSelected ? 'white' : 'black')};
    font-size: 15px;
`;

const FlexRow = styled.div`
    display: flex;
    justify-content: end;
`;

const SearchAndWriteRow = styled.div`
    display: flex;
    align-items: center;
`;

const SearchBox = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
`;

const SearchInput = styled.input`
    border: none;
    outline: none;
    padding: 5px;
    margin-left: 5px;
    font-size: 16px;
`;

const WriteButton = styled.button`
    margin-left: 20px;
    width: 70px;
    height: 30px;
    background-color: #282c34;
    color: white;
`;

const DivideHr = styled.hr``;

const DivideHrBold = styled.hr`
    height: 10px;
    background: black;
    border: 0;
`;

const DataTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const ThThumb = styled.th`
    width: 100px;
    background-color: #f4f4f4;
    padding: 8px;
`;

const ThTitle = styled.th`
    width: 60%;
    background-color: #f4f4f4;
    padding: 8px;
`;

const Th = styled.th`
    background-color: #f4f4f4;
    padding: 8px;
`;

const Td = styled.td`
    padding: 8px;
    text-align: center;
`;

export default ListPage;

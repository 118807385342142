import styled from "styled-components";

const Footer = () => {
    return (
        <>
            <HideH1>리니지 프리서버</HideH1>
            <hr style={{color: "#777777"}}/>
            <FooterBar>
                Copyright 시즌프리 - 리니지 프리서버 각종 홍보 사이트. All Rights Reserved.
            </FooterBar>
        </>
    );
}

const HideH1 = styled.h1`
    font-size: 14px;
    display: flex;
    justify-content: end;
    margin-bottom: 2px;
    margin-right: 10px;
    font-weight: normal;
`;

const FooterBar = styled.footer`
    height: 50px;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    font-size: 14px;
`;


export default Footer;
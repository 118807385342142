import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const PostBox = ({ gname, color, posts, category }) => {
    const navigate = useNavigate();

    const handlePostClick = (category, postId) => {
        navigate(`/${category}/${postId}`);
    };

    return (
        <PostBoxContainer>
            <BoxHeader>
                <GameTitle color={color}>{gname}</GameTitle>
                {/*<GameIcon src={gicon} alt="Game Icon" />*/}
            </BoxHeader>
            <DivideHr />
            <BoxContent>
                {posts.map((post, index) => (
                    <li key={index} onClick={() => handlePostClick(category, post.id)}>
                        <PostIndex>{index + 1}</PostIndex>
                        <PostTitle>{gname} | {post.title}</PostTitle>
                        <New src={"new.png"} alt="New Icon" />
                    </li>
                ))}
            </BoxContent>
        </PostBoxContainer>
    );
};

const PostBoxContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    //min-height: 250px;
    height: 230px;
    //margin: auto;
`;

const BoxHeader = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`;

const GameTitle = styled.h2`
    font-size: 1.5rem;
    color: ${(props) => props.color};
    //height: 10px;
    margin: 0;
`;

const BoxContent = styled.ul`
    margin: 0;
    padding: 0;
    cursor: pointer;

    li {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;
    }
`;

const PostIndex = styled.span`
    width: 20px;
    height: 20px;
    font-weight: bold;
    color: white;
    margin-top: 2px;
    margin-right: 5px;
    background-color: #ef3c39; // 배경색 설정 
    padding: 2px 6px; 
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PostTitle = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; // 최대 너비를 줄임
`;

const New = styled.img`
    width: 20px;
    height: 20px
`;

const DivideHr = styled.hr`
    border: 3px solid black; // 더 굵게 만들기 위해 3px로 설정
    margin: 0; // hr 요소의 여백 제거
`;

export default PostBox;
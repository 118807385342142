import {useEffect, useState} from "react";
import InputComponent from "../../components/common/Input";
import styled from "styled-components";
import Editor from "../../components/common/Editor";
import ButtonComponent from "../../components/common/Button";
import {useNavigate, useParams} from "react-router-dom";
import {WritePost} from "../../apis/BBS";
import Swal from "sweetalert2";
import {useAuthDispatch, useAuthState} from "../../context/Auth";
import ReCAPTCHA from "react-google-recaptcha";

const WritePage = () => {
    const { gameKey } = useParams();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [urlFirst, setUrlFirst] = useState("");
    const [urlSecond, setUrlSecond] = useState("");

    const [imageUrls, setImageUrls] = useState([]);
    const navigate = useNavigate();
    const { user } = useAuthState();
    const dispatch = useAuthDispatch();

    const API_KEY = "6Le_4PopAAAAAJLPOlPEe3XKZR-w2FC1xL5PA3is";
    const [captchaValue, setCaptchaValue] = useState("");

    useEffect(() => {
        if (user && (user.role !== 'USER' && user.role !== 'ADMIN')) {
            Swal.fire({
                icon: 'error',
                title: '접근 불가',
                text: '권한이 없습니다.',
            }).then(() => {
                window.location.href = '/';
            });
        }
    }, [user]);

    if (!user) {
        return <div>Loading...</div>; // 유저 정보를 불러오는 동안 로딩 상태를 표시합니다.
    }

    if (user.role !== 'USER' && user.role !== 'ADMIN') {
        return null;
    }

    const handleValueChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleSubmit = async () => {
        if (title.length === 0) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "제목을 작성해 주세요"
            });
            return;
        } else if (content.length === 0) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "내용을 작성해 주세요"
            });
            return;
        } else if (captchaValue.length === 0) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "ReCAPTCHA를 완료해 주세요"
            });
            return;
        }

        console.log(`gameKey: ${gameKey}`)
        await WritePost(user.id, title, content, urlFirst, urlSecond, gameKey, imageUrls, navigate, user, dispatch);
    };

    return (
        <div>
            <WriteHeader>게시글 작성</WriteHeader>
            <TextWrite>작성하기</TextWrite>
            <DivideHr />

            <TitleRow>
                <TitleText>제목</TitleText>
                <InputComponent
                    width={"100%"}
                    height={"25px"}
                    value={title}
                    onChange={handleValueChange(setTitle)}
                />
            </TitleRow>
            <DivideHr />

            <Editor value={content} onChange={(value) => setContent(value)} setImageUrls={setImageUrls} />

            <TitleRow style={{ marginBottom: "10px" }}>
                <TitleText>관련 링크</TitleText>
                <InputComponent
                    width={"100%"}
                    height={"25px"}
                    value={urlFirst}
                    onChange={handleValueChange(setUrlFirst)}
                    placeholder={"https://..."}
                />
            </TitleRow>
            <TitleRow>
                <TitleText>관련 링크</TitleText>
                <InputComponent
                    width={"100%"}
                    height={"25px"}
                    value={urlSecond}
                    onChange={handleValueChange(setUrlSecond)}
                    placeholder={"https://..."}
                />
            </TitleRow>
            <DivideHr />
            <ReCAPTCHA
                sitekey={API_KEY}
                onChange={(value) => {
                    setCaptchaValue(value || "");
                }}
            />
            <DivideHr />
            <ButtonRow>
                <ButtonComponent
                    height={"50px"}
                    text={"취소"}
                    background={"white"}
                    fontsize={"20px"}
                    onClick={() => navigate(`/${gameKey}`)}
                />
                <ButtonComponent
                    height={"50px"}
                    text={"확인"}
                    background={"black"}
                    color={"white"}
                    fontsize={"20px"}
                    onClick={handleSubmit}
                />
            </ButtonRow>
        </div>
    );
};

const WriteHeader = styled.span`
    width: 100%;
    font-size: 40px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
    text-align: center;
`;

const TextWrite = styled.span`
    font-weight: bold;
    font-size: 25px;
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center; // 세로 중앙 정렬
`;

const TitleText = styled.span`
    width: auto; // 너비 자동 조절
    flex-shrink: 0; // 크기 축소 방지
    margin-right: 20px; // 오른쪽 여백 설정
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 100px;
`;

const DivideHr = styled.hr``;

export default WritePage;

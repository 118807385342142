import axiosInstance from "./Axios";
import Swal from "sweetalert2";

export const CalendarApi = async (userId, comment) => {
    console.log(`Sending data: userId=${userId} & comment=${comment}`);

    try {
        const response = await axiosInstance.post('/attendance/check', {
            id: userId, comment
        });
        Swal.fire({
            title: "성공",
            text: "출석체크에 성공하였습니다.",
            icon: "success"
        });
        return response.data || [];
    } catch (err) {
        console.error('출석체크 오류 발생함', err);
        if (err.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: err.response.data.message
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "출석체크 중 오류가 발생했습니다.",
            });
        }
        return [];
    }
}

export const AttendanceDateArrayCalendar = async (userId, month) => {

    try {
        const response = await axiosInstance.post('/attendance/list', {
            id: userId,
            month: month
        });
        return response.data || [];
    } catch (err) {
        console.error('출석체크 오류 발생함', err);
        if (err.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: err.response.data,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "출석체크 중 오류가 발생했습니다.",
            });
        }
        return [];
    }
};

import Swal from "sweetalert2";
import axiosInstance from "./Axios";
import {useAuthDispatch, useAuthState} from "../context/Auth";

export const WritePost = async (userId, title, content, urlFirst, urlSecond, gameKey, imageUrls, navigate, user, dispatch) => {
    try {
        const response = await axiosInstance.post('/bbs/write', {
            id: userId,
            title,
            content,
            urlOne: urlFirst,
            urlTwo: urlSecond,
            imageUrl: imageUrls,
            postType: 'GENERAL', // Example post type
            gameCategory: gameKey.toUpperCase() // Use gameCategory instead of categoryType
        });
        // 글 작성 성공 시 포인트 업데이트 API 호출
        const pointsResponse = await axiosInstance.get(`/user/points/${user.id}`);
        dispatch({
            type: 'UPDATE_POINTS',
            payload: {points: pointsResponse.data.points},
        });
        Swal.fire({
            title: "작성완료",
            text: "게시글이 성공적으로 작성되었습니다!",
            icon: "success",
        }).then(() => {
            navigate(`/${gameKey}`);
        });
    } catch (error) {
        if (error.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: error.response.data.message
            });
        } else {
            console.error('Error:', error);
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "네트워크 오류가 발생했습니다. 나중에 다시 시도해 주세요.",
            });
        }
    }
}

export const GetPost = async (gameKey, page) => {
    try {
        const response = await axiosInstance.get(`/bbs/${gameKey}`, {
            params: {
                page: page - 1, // 백엔드의 페이지 번호는 0부터 시작
                size: 15 // 페이지 당 게시글 수
            }
        });
        const {content, totalElements} = response.data; // 응답 구조가 Page<PostDTO> 형태일 경우

        return {
            posts: content || [],
            totalRows: totalElements || 0
        };
    } catch (error) {
        if (error.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: error.response.data.message,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "네트워크 오류가 발생했습니다. 나중에 다시 시도해 주세요.",
            });
        }
    }
}

export const ImageUrl = async (formData) => {
    return await axiosInstance.post('/bbs/image-url', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}


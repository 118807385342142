import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faLessThan, faGreaterThan, faAnglesRight } from '@fortawesome/free-solid-svg-icons';

const renderPagination = ({
                              startPage,
                              endPage,
                              currentPage,
                              handleClick,
                              handleFirstPage,
                              handlePrevPageGroup,
                              handleNextPageGroup,
                              handleLastPage,
                              totalPages,
                          }) => {
    const buttons = [];

    for (let i = startPage; i <= endPage; i++) {
        buttons.push(
            <PageButton key={i} active={i === currentPage} onClick={() => handleClick(i)}>
                {i}
            </PageButton>
        );
    }

    return (
        <Pagination>
            <PageButton onClick={handleFirstPage} disabled={currentPage === 1}>
                <FontAwesomeIcon icon={faAnglesLeft} />
            </PageButton>
            <PageButton onClick={handlePrevPageGroup} disabled={startPage === 1}>
                <FontAwesomeIcon icon={faLessThan} />
            </PageButton>
            {buttons}
            <PageButton onClick={handleNextPageGroup} disabled={endPage === totalPages}>
                <FontAwesomeIcon icon={faGreaterThan} />
            </PageButton>
            <PageButton onClick={handleLastPage} disabled={currentPage === totalPages}>
                <FontAwesomeIcon icon={faAnglesRight} />
            </PageButton>
        </Pagination>
    );
};

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const PageButton = styled.button`
    margin: 0 5px;
    padding: 5px 10px;
    background: ${(props) => (props.active ? '#000' : '#fff')};
    color: ${(props) => (props.active ? '#fff' : '#000')};
    border: 1px solid #000;
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

export default renderPagination;

import styled from "styled-components";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../apis/Axios";
import {useAuthState} from "../../context/Auth";
import Swal from 'sweetalert2';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

/**
 * [제목]
 * ==========================
 * 작성자  작성시간    조회수
 *
 *     내용
 *
 *    (사진)
 * (본인이 쓴 게시글이면)수정/삭제
 * ==========================
 *     댓글
 * */

const DetailPage = () => {
    const { gameKey, postId } = useParams();
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [text, setText] = useState('');
    const maxLength = 255;
    const { user } = useAuthState();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPostDetail = async () => {
            try {
                const postResponse = await axiosInstance.get(`/bbs/${gameKey}/${postId}`);
                setPost(postResponse.data);

                const commentsResponse = await axiosInstance.get(`/bbs/comment/${postId}`);
                setComments(commentsResponse.data);
            } catch (error) {
                console.error('게시글 및 댓글 가져오기 실패:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPostDetail();
    }, [gameKey, postId]);

    const handleChange = (event) => {
        if (event.target.value.length <= maxLength) {
            setText(event.target.value);
        } else {
            alert(`최대 ${maxLength}자까지 입력 가능합니다.`);
        }
    };

    const handleCommentSubmit = async () => {
        if (!text.trim()) {
            alert('댓글을 입력해주세요.');
            return;
        }

        console.log(`userID: ${user.id}`);
        try {
            await axiosInstance.post(`/bbs/add-comment`, {
                userId: user.id,
                postId: parseInt(postId),
                comment: text,
            });

            const commentsResponse = await axiosInstance.get(`/bbs/comment/${postId}`);
            setComments(commentsResponse.data);
            setText('');
        } catch (error) {
            console.error('댓글 작성 실패:', error);
        }
    };

    const updatePost = async () => {
        try {
            navigate(`/edit/${gameKey}/${postId}`);
        } catch (error) {
            console.error('게시글 업데이트 실패:', error);
        }
    };

    const deletePost = async () => {
        try {
            const result = await Swal.fire({
                icon: 'question',
                title: '게시글 삭제',
                text: '정말로 게시글을 삭제하시겠어요?',
                showCancelButton: true,
                confirmButtonText: '삭제',
                cancelButtonText: '취소',
            });

            if (result.isConfirmed) {
                await axiosInstance.delete(`/bbs/${postId}`, {
                    params: {
                        userId: user.id,
                    },
                });

                Swal.fire({
                    icon: 'success',
                    title: '삭제 완료',
                    text: '게시글이 삭제되었습니다.',
                }).then(() => {
                    navigate(-1);
                });
            }
        } catch (error) {
            console.error('게시판 삭제 실패');
        }
    };

    const editComment = async (commentId, newContent) => {
        try {
            console.log(`newContent: ${newContent}`);
            await axiosInstance.patch(`/bbs/comment-update`, {
                userId: user.id,
                commentId,
                comment: newContent,
            });

            const commentsResponse = await axiosInstance.get(`/bbs/comment/${postId}`);
            console.log(`data: ${JSON.stringify(commentsResponse.data)}`)
            setComments(commentsResponse.data);
        } catch (error) {
            console.error('댓글 수정 실패:', error);
        }
    };

    const deleteComment = async (commentId) => {
        const result = await Swal.fire({
            icon: 'question',
            title: '댓글 삭제',
            text: '정말로 댓글을 삭제하시겠어요?',
            showCancelButton: true,
            confirmButtonText: '삭제',
            cancelButtonText: '취소',
        });

        if (result.isConfirmed) {
            try {
                await axiosInstance.delete(`/bbs/comment`, {
                    data: { userId: user.id, commentId },
                });

                const commentsResponse = await axiosInstance.get(`/bbs/comment/${postId}`);
                setComments(commentsResponse.data);
            } catch (error) {
                console.error('댓글 삭제 실패:', error);
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!post) {
        return <div>게시글을 찾을 수 없습니다.</div>;
    }

    const isAuthorOrAdmin = user && (user.role === 'ADMIN' || user.id === post.userId);

    return (
        <DetailBox>
            <DetailHeader>{post.title}</DetailHeader>
            <WriteHeader>
                <Writer>작성자: {post.author}</Writer>
                <WriteTime>작성시간: {post.writeDate}</WriteTime>
                <WatchCount>조회수: {post.watch}</WatchCount>
            </WriteHeader>
            <DivideHr />
            {isAuthorOrAdmin && (
                <EditHeader>
                    <EditButton onClick={updatePost}>편집하기</EditButton>
                    <EditButton onClick={deletePost}>삭제하기</EditButton>
                </EditHeader>
            )}
            <DetailBody dangerouslySetInnerHTML={{ __html: post.content }} />
            <DivideHr />

            {post.urlOne || post.urlTwo ? (
                <>
                    <CommentHeader>관련링크</CommentHeader>
                    {post.urlOne && (
                        <Link href={post.urlOne} target="_blank" rel="noopener noreferrer">
                            {post.urlOne}
                        </Link>
                    )}
                    {post.urlTwo && (
                        <Link href={post.urlTwo} target="_blank" rel="noopener noreferrer">
                            {post.urlTwo}
                        </Link>
                    )}
                    <DivideHr />
                </>
            ) : null}

            <CommentHeader>댓글</CommentHeader>
            <CommentList
                comments={comments}
                handleCommentUpdate={editComment}
                handleCommentDelete={deleteComment}
                user={user}
            />
            {user ? (
                <CommentBox>
                    <CommentInput
                        value={text}
                        onChange={handleChange}
                        placeholder="최대 255자까지 입력 가능합니다."
                    />
                    <CommentButton onClick={handleCommentSubmit}>등록</CommentButton>
                </CommentBox>
            ) : (
                <CommentBox>
                    <CommentInput
                        placeholder="로그인 후 댓글을 작성할 수 있습니다."
                        disabled
                    />
                    <CommentButton disabled>등록</CommentButton>
                </CommentBox>
            )}
        </DetailBox>
    );
};

const formatDate = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}.${String(date.getDate()).padStart(2, '0')}`;
    const formattedTime = date.toLocaleTimeString('ko-KR', {hour: '2-digit', minute: '2-digit', hour12: false});

    return `${formattedDate} ${formattedTime}`;
};

const CommentList = ({ comments, handleCommentUpdate, handleCommentDelete, user }) => {
    const [editCommentId, setEditCommentId] = useState(null);
    const [newCommentText, setNewCommentText] = useState('');

    const startEditing = (commentId, currentText) => {
        setEditCommentId(commentId);
        setNewCommentText(currentText);
    };

    const stopEditing = () => {
        setEditCommentId(null);
        setNewCommentText('');
    };

    const handleUpdateClick = async (commentId) => {
        await handleCommentUpdate(commentId, newCommentText);
        stopEditing();
    };

    return (
        <>
            {comments.map((comment, index) => (
                <CommentContainer key={index}>
                    <ProfileIcon>
                        {comment.imageUrl ? (
                            <ProfileImage src={comment.imageUrl} alt="user profile" />
                        ) : (
                            <FontAwesomeIcon icon={faUser} size="lg" />
                        )}
                    </ProfileIcon>
                    <CommentContent>
                        <CommentRowFirst>
                            <Nickname>{comment.userNickname}</Nickname>
                            {user && user.id === comment.userId && (
                                <ActionButtons>
                                    <EditButton onClick={() => startEditing(comment.id, comment.content)}>수정하기</EditButton>
                                    <DeleteButton onClick={() => handleCommentDelete(comment.id)}>삭제하기</DeleteButton>
                                </ActionButtons>
                            )}
                        </CommentRowFirst>
                        {editCommentId === comment.id ? (
                            <CommentRowSecond>
                                <CommentInput
                                    value={newCommentText}
                                    onChange={(e) => setNewCommentText(e.target.value)}
                                />
                                <SaveButton onClick={() => handleUpdateClick(comment.id)}>저장</SaveButton>
                                <CancelButton onClick={stopEditing}>취소</CancelButton>
                            </CommentRowSecond>
                        ) : (
                            <CommentRowSecond>
                                <CommentText>{comment.content}</CommentText>
                                <RegisterTime>{formatDate(comment.writeAt)}</RegisterTime>
                            </CommentRowSecond>
                        )}
                    </CommentContent>
                </CommentContainer>
            ))}
        </>
    );
};


const DetailBox = styled.div`
    width: 80%;
    margin-bottom: 80px;
`;

const Link = styled.a`
    display: block;
    margin: 0.5em 0;
    color: blue;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
`;


const DetailHeader = styled.span`
    width: 100%;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: start;
    padding: 20px 0;
    margin-bottom: 20px;
    text-align: center;
`;

const EditHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`;

const EditButton = styled.span`
    margin-right: 10px;
    cursor: pointer;
`;

const DeleteButton = styled.span`
    cursor: pointer;
`;

const SaveButton = styled.button`
  
`;

const CancelButton = styled.button`
  
`;

const WriteHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Writer = styled.span`
    flex-grow: 1; /* 나머지 공간을 모두 차지하도록 설정 */
`;

const WriteTime = styled.span`
    margin-right: 20px; /* 시각적으로 구분을 주기 위해 오른쪽에 여백을 줌 */
`;

const WatchCount = styled.span`
    /* 추가적인 스타일링이 필요하면 여기에 작성 */
`;


const DivideHr = styled.hr``;

const DetailBody = styled.div`
    width: 100%;
    padding: 20px 0;

    img {
        max-width: 100%; /* 이미지의 최대 너비를 100%로 설정 */
        height: auto; /* 높이를 자동으로 조절 */
        display: block;
        margin: 0 auto; /* 이미지를 중앙 정렬 */
    }
`;

const CommentHeader = styled.span`
    width: 100%;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: start;
    padding: 10px 0;
    //margin-bottom: 20px;
    text-align: center;
`;

const CommentContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    align-items: flex-start;
`;

const ProfileIcon = styled.div`
    width: 50px;
    height: 50px;
    background-color: gray;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right: 20px;

    svg {
        width: 100%;
        height: auto;
        color: white;
    }
`;

const ProfileImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const CommentContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const CommentText = styled.span`
    margin: 5px 0;
    white-space: pre-line;
`;

const CommentRowFirst = styled.div`
    flex-direction: row;
    margin-bottom: 5px;
    position: relative;
`;

const ActionButtons = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

const CommentRowSecond = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const RegisterTime = styled.span`
    color: gray;
    font-weight: bold;
    align-self: flex-end;
`;

const Nickname = styled.span`
    margin-left: 2px;
    color: gray;
    font-weight: bold;
`;

const CommentBox = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
`;

const CommentInput = styled.textarea`
    width: 90%;
    height: 80px;
    margin-right: 10px;
    overflow-y: auto;
    resize: none;
`;

const CommentButton = styled.button`
    width: 10%;
    background-color: black;
    color: white;
    font-size: 23px;
    border-radius: 4px;
`;

export default DetailPage;
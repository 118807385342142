import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import styled from "styled-components";
import ButtonComponent from "../../components/common/Button";
import InputComponent from "../../components/common/Input";
import Editor from "../../components/common/Editor";
import axiosInstance from "../../apis/Axios";
import {useAuthState} from "../../context/Auth";
import ReCAPTCHA from "react-google-recaptcha";

const EditPage = () => {
    const {gameKey, postId} = useParams();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [urlFirst, setUrlFirst] = useState('');
    const [urlSecond, setUrlSecond] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const {user} = useAuthState();

    const API_KEY = "6Le_4PopAAAAAJLPOlPEe3XKZR-w2FC1xL5PA3is";
    const [captchaValue, setCaptchaValue] = useState("");

    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const {data} = await axiosInstance.get(`/bbs/${gameKey}/${postId}`);
                console.log(`title: ${data.title}`)
                setTitle(data.title);
                setContent(data.content);
                setUrlFirst(data.urlFirst || '');
                setUrlSecond(data.urlSecond || '');

                // Check if the logged-in user is the author or an admin
                if (data.userId !== user.id && user.role !== 'ADMIN') {
                    Swal.fire({
                        icon: 'error',
                        title: '접근 불가',
                        text: '작성자 또는 관리자만 접근할 수 있습니다.',
                    }).then(() => {
                        navigate(-1);
                    });
                } else {
                    setIsLoading(false); // 데이터 로드 완료 시 로딩 상태를 false로 설정
                }
            } catch (error) {
                console.error('게시글 불러오기 실패:', error);
                Swal.fire({
                    icon: 'error',
                    title: '오류',
                    text: '게시글을 불러오는 중 오류가 발생했습니다.',
                }).then(() => {
                    navigate(-1);
                });
            }
        };

        // user가 로드될 때까지 기다림
        if (user) {
            fetchPostData();
        }
    }, [postId, user, navigate, gameKey]);

    const handleValueChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleSubmit = async () => {
        if (title.length === 0) {
            Swal.fire({
                icon: 'error',
                title: '에러',
                text: '제목을 작성해 주세요',
            });
            return;
        } else if (content.length === 0) {
            Swal.fire({
                icon: 'error',
                title: '에러',
                text: '내용을 작성해 주세요',
            });
            return;
        } else if (captchaValue.length === 0) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "ReCAPTCHA를 완료해 주세요"
            });
            return;
        }

        try {
            await axiosInstance.patch(`/bbs/${postId}`, {
                title,
                content,
                urlOne: urlFirst,
                urlTwo: urlSecond,
                id: user.id,
            });
            Swal.fire({
                icon: 'success',
                title: '수정 완료',
                text: '게시글이 수정되었습니다.',
            }).then(() => {
                navigate(`/${gameKey}`);
            });
        } catch (error) {
            console.error('게시글 업데이트 실패:', error);
            Swal.fire({
                icon: 'error',
                title: '오류',
                text: '게시글을 수정하는 중 오류가 발생했습니다.',
            });
        }
    };

    if (isLoading) {
        return <div>로딩 중...</div>; // 데이터 로딩 중 표시할 컴포넌트
    }

    return (
        <div>
            <WriteHeader>게시글 수정</WriteHeader>
            <TextWrite>수정하기</TextWrite>
            <DivideHr/>

            <TitleRow>
                <TitleText>제목</TitleText>
                <InputComponent
                    width={'100%'}
                    height={'25px'}
                    value={title}
                    onChange={handleValueChange(setTitle)}
                />
            </TitleRow>
            <DivideHr/>

            <Editor value={content} onChange={(value) => setContent(value)}/>

            <TitleRow style={{marginBottom: '10px'}}>
                <TitleText>관련 링크</TitleText>
                <InputComponent
                    width={'100%'}
                    height={'25px'}
                    value={urlFirst}
                    onChange={handleValueChange(setUrlFirst)}
                    placeholder={'https://...'}
                />
            </TitleRow>
            <TitleRow>
                <TitleText>관련 링크</TitleText>
                <InputComponent
                    width={'100%'}
                    height={'25px'}
                    value={urlSecond}
                    onChange={handleValueChange(setUrlSecond)}
                    placeholder={'https://...'}
                />
            </TitleRow>
            <DivideHr/>
            <ReCAPTCHA
                sitekey={API_KEY}
                onChange={(value) => {
                    setCaptchaValue(value || "");
                }}
            />
            <DivideHr/>
            <ButtonRow>
                <ButtonComponent
                    height={'50px'}
                    text={'취소'}
                    background={'white'}
                    fontsize={'20px'}
                    onClick={() => navigate(`/${gameKey}`)}
                />
                <ButtonComponent
                    height={'50px'}
                    text={'확인'}
                    background={'black'}
                    color={'white'}
                    fontsize={'20px'}
                    onClick={handleSubmit}
                />
            </ButtonRow>
        </div>
    );
};

const WriteHeader = styled.span`
    width: 100%;
    font-size: 40px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
    text-align: center;
`;

const TextWrite = styled.span`
    font-weight: bold;
    font-size: 25px;
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center; // 세로 중앙 정렬
`;

const TitleText = styled.span`
    width: auto; // 너비 자동 조절
    flex-shrink: 0; // 크기 축소 방지
    margin-right: 20px; // 오른쪽 여백 설정
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 100px;
`;

const DivideHr = styled.hr``;

export default EditPage;

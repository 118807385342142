import React, {useRef, useEffect, useMemo} from 'react';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';
import {ImageUrl} from "../../apis/BBS";
import ImageResize from "quill-image-resize-module-react/src/ImageResize";
import {ImageDrop} from "quill-image-drop-module";
import ReactQuill from "react-quill"; // Quill CSS

window.Quill = Quill;
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);

const Editor = ({value, onChange, setImageUrls}) => {
    const quillRef = useRef(null);

    const handleImageUpload = async (files) => {
        const formData = new FormData();
        Array.from(files).forEach(file => formData.append('files', file)); // 여러 파일을 FormData에 추가

        try {
            const response = await ImageUrl(formData); // API 호출
            if (response.status === 200 || response.status === 201) {
                const result = response.data;
                const urls = result.imageUrls; // 서버에서 반환한 이미지 URL들

                // Quill 편집기에 이미지 삽입
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection(true);
                urls.forEach(url => {
                    quill.insertEmbed(range.index, 'image', url);
                    quill.setSelection(range.index + 1);
                });

                // 이미지 URL 상태 업데이트
                setImageUrls((prevUrls) => [...prevUrls, ...urls]);
            } else {
                console.error('이미지 처리 중 에러가 발생했습니다.');
            }
        } catch (err) {
            console.error('Error:', err);
        }
    };

    const handleImage = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.setAttribute('multiple', true); // 다중 파일 선택 가능
        input.click();

        input.onchange = async () => {
            const files = input.files;
            if (files.length > 0) {
                await handleImageUpload(files);
            }
        };
    };

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.getModule('imageDrop').handleDrop = async (event) => {
                event.preventDefault();
                const files = event.dataTransfer.files;
                if (files.length > 0) {
                    await handleImageUpload(files);
                }
            };
        }
    }, [quillRef]);

    const toolbarOptions = useMemo(() => [
        ["link", "image"],
        [{header: [1, 2, 3, false]}],
        ["bold", "italic", "underline", "strike"],
        ["blockquote"],
        [{list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent: "+1"}],
        [{color: []}, {background: []}],
        [{align: []}],
    ], []);

    const formats = useMemo(() => [
        'font', 'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'formula',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'align', 'color', 'background',
    ], []);

    const modules = useMemo(() => ({
        toolbar: {
            container: toolbarOptions,
            handlers: {image: handleImage},
        },
        imageDrop: true,
        imageResize: {
            modules: ['Resize', 'DisplaySize']
        },
    }), [toolbarOptions]);

    return (
        <div style={{marginBottom: "50px"}}>
            <ReactQuill
                style={{width: "800px", height: "500px"}}
                ref={quillRef}
                modules={modules}
                formats={formats}
                value={value}
                onChange={onChange}
            />
        </div>
    );
};

export default Editor;

import Swal from "sweetalert2";
import axiosInstance from "./Axios";
import {jwtDecode} from "jwt-decode";

export const LoginApi = async (userId, password, dispatch) => {
    console.log('로그인 시도 중...');

    try {
        const response = await axiosInstance.post('/user/login', {userId, password});

        const accessToken = response.data.access_token;
        const refreshToken = response.data.refresh_token;

        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('refresh_token', refreshToken);
        console.log('로그인 성공');

        // JWT 디코딩
        const decodedToken = jwtDecode(accessToken);
        const id = decodedToken.id;

        // // 사용자 정보 가져오기
        const userInfoResponse = await axiosInstance.get(`/user/info?id=${id}`);

        const userInfoResponseData = userInfoResponse.data;

        // setUserInfo에 올바르게 설정
        const user = {
            id: userInfoResponseData.id,
            email: userInfoResponseData.email,
            role: userInfoResponseData.role,
            nickname: userInfoResponseData.nickname,
            points: userInfoResponseData.points || 0,
            imageUrl: userInfoResponseData.imageUrl || '',
        };

        console.log('Dispatching user info:', user);
        dispatch({type: 'LOGIN', payload: {user, accessToken, refreshToken}});
        console.log('로그인 및 사용자 정보 저장 완료');

    } catch (error) {
        console.error('로그인 시도 중 오류발생:', JSON.stringify(error));
        if (error.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: error.response.data.message,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "네트워크 오류가 발생했습니다. 나중에 다시 시도해 주세요.",
            });
        }
    }
};

export const SendEmailApi = async (email, setVerificationSent, setSendEmail) => {
    try {
        const response = await axiosInstance.post('/user/email-check', {
            email
        });
        Swal.fire({
            title: "전송완료",
            text: "30분 이내에 인증번호를 입력 해 주세요.",
            icon: "success"
        });
        setVerificationSent(true);
        setSendEmail(true);
    } catch (err) {
        if (err.response) {
            if (err.response.status === 400) {
                Swal.fire({
                    icon: "error",
                    title: "에러",
                    text: err.response.data,  // 서버에서 반환한 메시지를 그대로 표시
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "에러",
                    text: "메일 전송 중 오류가 발생하였습니다.",
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "메일 전송 중 오류가 발생하였습니다.",
            });
        }
        setVerificationSent(false);
        setSendEmail(false);
    }
}

// 인증완료
export const ValidateEmail = async (email, otp, setVerificationSent, setUserId) => {
    try {
        const response = await axiosInstance.post('/user/check-email-otp', {
            email, otp
        });
        setVerificationSent(true);
        setUserId(response.data);
    } catch (err) {
        if (err.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: err.response.data,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "메일 전송 중 오류가 발생하였습니다.",
            });
        }
    }
}

export const ProfileImageUrl = async (formData) => {
    try {
        const response = await axiosInstance.post('/user/profile-image-url', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    } catch (err) {
        if (err.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: JSON.stringify(err.response.data),
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "프로필 이미지 처리 중 오류가 발생하였습니다.",
            });
        }
    }
}

export const JoinApi = async (formData, onClose) => {
    try {
        await axiosInstance.post('/user/join', formData);
        Swal.fire({
            title: "축하합니다!",
            text: "회원가입을 환영합니다!",
            icon: "success"
        }).then((result) => {
            if (result.isConfirmed) {
                onClose();
            }
        });
    } catch (err) {
        if (err.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: JSON.stringify(err.response.data),
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "회원가입 처리 중 오류가 발생하였습니다.",
            });
        }
    }
}

export const SendEmail = async (email, setSendEmail) => {
    try {
        // 인증번호 전송
        const response = await axiosInstance.post('/user/email-otp-check', {email});
        Swal.fire({
            title: "성공",
            text: "인증번호를 가입하신 이메일로 전송하였습니다.\n인증번호는 30분간 유효합니다.",
            icon: "success"
        });
        setSendEmail(true);
    } catch (err) {
        if (err.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: err.response.data,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "이메일 전송 처리 중 오류가 발생하였습니다.",
            });
        }
    }
}

export const VerifyEmailAndReturnId = async (userId, email, setSendEmail, setVerificationSent) => {
    try {
        const response = await axiosInstance.post('/user/find-id-password', {userId, email});
        Swal.fire({
            title: "성공",
            text: "인증번호를 가입하신 이메일로 전송하였습니다.\n인증번호는 30분간 유효합니다.",
            icon: "success"
        })
    } catch (err) {
        setSendEmail(false); // 블러 취소
        setVerificationSent(false); // 블러 취소

        if (err.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: err.response.data,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "이메일 인증 중 오류가 발생하였습니다.",
            });
        }
    }
}

export const ChangePasswordApi = async (email, newPassword) => {
    try {
        const response = await axiosInstance.post('/user/update-password', {email, newPassword});
    } catch (err) {
        if (err.response) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: err.response.data,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "비밀번호 변경 중 오류가 발생하였습니다.",
            });
        }
    }
}

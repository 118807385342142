import styled from "styled-components";
import { useEffect, useState } from "react";
import {useNavigate, useLocation, useParams, Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLessThan,
  faGreaterThan,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import {fetchCSList} from "../../apis/QnA";
import {useAuthState} from "../../context/Auth";
import Swal from "sweetalert2";

const POSTS = Array.from({ length: 10 }, (_, i) => ({
  id: i + 1,
  title: "",
  content: "",
  createdAt: "",
  editor: "",
}));

const PaginationComponent = ({ onPageChange }) => {
  const [totalRows, setTotalRows] = useState(0);
  const rowsPerPage = 10;

  const navigate = useNavigate();
  const location = useLocation();

  // Get the current page from URL query
  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get('page')) || 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { total } = await fetchCSList(currentPage - 1, rowsPerPage);
        setTotalRows(total);
      } catch (error) {
        console.error('Error fetching total rows:', error);
      }
    };

    fetchData();
  }, [currentPage]);

  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const maxPageButtons = 10;
  const startPage = Math.floor((currentPage - 1) / maxPageButtons) * maxPageButtons + 1;
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  const handleClick = (pageNumber) => {
    navigate(`?page=${pageNumber}`);
    onPageChange(pageNumber);
  };

  const handlePrevPageGroup = () => {
    if (startPage > 1) {
      handleClick(startPage - 1);
    }
  };

  const handleNextPageGroup = () => {
    if (endPage < totalPages) {
      handleClick(endPage + 1);
    }
  };

  const handleFirstPage = () => {
    handleClick(1);
  };

  const handleLastPage = () => {
    handleClick(totalPages);
  };

  const renderPagination = () => {
    const buttons = [];

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
          <PageButton key={i} active={i === currentPage} onClick={() => handleClick(i)}>
            {i}
          </PageButton>
      );
    }

    return (
        <Pagination>
          <PageButton onClick={handleFirstPage} disabled={currentPage === 1}>
            <FontAwesomeIcon icon={faAnglesLeft} />
          </PageButton>
          <PageButton onClick={handlePrevPageGroup} disabled={startPage === 1}>
            <FontAwesomeIcon icon={faLessThan} />
          </PageButton>
          {buttons}
          <PageButton onClick={handleNextPageGroup} disabled={endPage === totalPages}>
            <FontAwesomeIcon icon={faGreaterThan} />
          </PageButton>
          <PageButton onClick={handleLastPage} disabled={currentPage === totalPages}>
            <FontAwesomeIcon icon={faAnglesRight} />
          </PageButton>
        </Pagination>
    );
  };

  return renderPagination();
};

const QnAList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthState();

  const fetchPosts = async (pageIndex = 0) => {
    setLoading(true);
    try {
      const { data } = await fetchCSList(pageIndex, 10); // 10은 페이지 당 행 수입니다.
      setPosts(data);
    } catch (error) {
      console.error('Error fetching CS list', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user || (user.role !== 'USER' && user.role !== 'ADMIN')) {
      Swal.fire({
        icon: 'error',
        title: '접근 불가',
        text: '권한이 없습니다.',
        confirmButtonText: '확인'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/';
        }
      });
    }
    fetchPosts();
  }, [user]);

  if (!user || (user.role !== 'USER' && user.role !== 'ADMIN')) {
    return null;
  }

  const handlePageChange = (pageNumber) => {
    fetchPosts(pageNumber - 1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
      <Container className="container">
        <Title>고객 문의</Title>
        <ListContainer>
          <ListRow style={{ textAlign: 'center' }}>
            <Post>
              <PostEtc>작성일</PostEtc>
              <PostTitle>제목</PostTitle>
              <PostEtc>작성자</PostEtc>
            </Post>
          </ListRow>
          {loading ? (
              <div>Loading...</div>
          ) : (
              posts.map((post) => (
                  <ListRow key={post.id}>
                    <Post>
                      <PostEtc>{formatDate(post.askDate)}</PostEtc>
                      <PostTitle>
                        <StyledLink to={`/qna/detail/${post.id}`}>{post.title}</StyledLink>
                      </PostTitle>
                      <PostEtc>{post.writer}</PostEtc>
                    </Post>
                  </ListRow>
              ))
          )}
        </ListContainer>
        <PaginationComponent onPageChange={handlePageChange} />
      </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 100vw;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  font-weight: bold;
`;

const ListContainer = styled.div`
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
`;

// Row
const ListRow = styled.div`
  width: 100%;
  padding: 10px;
  border-top: 1px solid black;
`;

const Post = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
const PostEtc = styled.div`
  min-width: 100px;
  display: flex;
  align-items: center;
  font-size: 12px;
`;
const PostTitle = styled.div`
  flex: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  text-align: start;
`;
const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

// Pagination
const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
const PageButton = styled.button`
  background-color: ${(props) => (props.active ? "blue" : "white")};
  color: ${(props) => (props.active ? "white" : "black")};
  border: 1px solid black;
  margin: 0 5px;
  padding: 8px 16px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export default QnAList;

import React, { useRef, useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { FaPen, FaTrash } from "react-icons/fa";
import { useAuthState } from "../../context/Auth";
import Swal from "sweetalert2";
import axiosInstance from "../../apis/Axios";

const SizeBox = ({ width, height, order, initialImage, onClick, bannerClickUrl }) => {
    const [image, setImage] = useState(initialImage);
    const { user } = useAuthState();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleOpenPopup = useCallback(() => {
        setIsPopupOpen(true);
        onClick();
    }, [onClick]);

    const handleClosePopup = useCallback(() => {
        setIsPopupOpen(false);
    }, []);

    const deleteImage = useCallback(async () => {
        try {
            await axiosInstance.post(`/banner/delete`, { orderNo: order, width, height });
            setImage(null);
            Swal.fire({
                title: "성공",
                text: "배너 삭제에 성공하였습니다.",
                icon: "success"
            });
        } catch (error) {
            console.error('이미지 삭제 중 오류 발생:', error);
            if (error.response) {
                Swal.fire({
                    icon: "error",
                    title: "에러",
                    text: error.response.data.message
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "에러",
                    text: "배너 삭제 중 오류가 발생했습니다.",
                });
            }
        }
    }, [order, width, height]);

    useEffect(() => {
        setImage(initialImage);
    }, [initialImage]);

    return (
        <BannerSizeBox width={width} height={height} className="banner-sizebox" isAdmin={user?.role === 'ADMIN'}>
            {user?.role === 'ADMIN' && (
                <ButtonContainer>
                    <UploadButton onClick={handleOpenPopup}>
                        <FaPen />
                    </UploadButton>
                    {!!image && (
                        <UploadButton className="delete" onClick={deleteImage}>
                            <FaTrash />
                        </UploadButton>
                    )}
                </ButtonContainer>
            )}
            {!!image && (
                <a href={bannerClickUrl || "#"} target="_blank" rel="noopener noreferrer">
                    <img
                        src={image}
                        alt="upload"
                        style={{ width: "100%", height: "100%" }}
                    />
                </a>
            )}
        </BannerSizeBox>
    );
};

const BannerSizeBox = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border: ${(props) => (props.isAdmin ? '1px solid #282c34' : 'none')};
    display: block;
    margin: 5px 0;
    position: relative;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 5px;
    position: absolute;
    top: 10px;
    right: 5px;

    & .check {
        background-color: #70e07f;
    }
`;

const UploadButton = styled.button`
    padding: 8px;
    border-radius: 5px;
    background-color: #282c34;
    color: white;
    border: none;
    cursor: pointer;
`;

export default SizeBox;

import styled from "styled-components";
import React, { useState } from "react";
import InputComponent from "../../components/common/Input";
import ButtonComponent from "../../components/common/Button";
import Popup from "../../components/common/Popup";
import Join from "./Join";
import FindIdOrPassword from "./FindIdOrPassword";
import { LoginApi } from "../../apis/User";
import { useAuthDispatch } from "../../context/Auth";
import LoginImg from "../../assets/images/login/loginImage.png";
import UserLogo from "../../assets/images/login/userLogo.png";
import LockLogo from "../../assets/images/login/lockLogo.png";

const Login = () => {
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [isJoinPopupOpen, setIsJoinPopupOpen] = useState(false);
    const [isFindIdOrPasswordOpen, setIsFindIdOrPasswordOpen] = useState(false);

    const handleJoinOpenPopup = () => {
        setIsJoinPopupOpen(true);
    };

    const handleJoinClosePopup = () => {
        setIsJoinPopupOpen(false);
    };

    const handleOpenFindIdOrPassword = () => {
        setIsFindIdOrPasswordOpen(true);
    };

    const handleFindIdOrPasswordClosePopup = () => {
        setIsFindIdOrPasswordOpen(false);
    };

    const dispatch = useAuthDispatch();

    const handleLogin = async () => {
        await LoginApi(userId, password, dispatch);
    };

    return (
        <LoginContainer>
            <LoginImage src={LoginImg} alt={"로그인 이미지"} />
            <InputContainer>
                <Icon src={UserLogo} alt="User Icon" />
                <InputComponent
                    width={"320px"}
                    height={"30px"}
                    type="text"
                    placeholder="아이디"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                />
            </InputContainer>
            <InputContainer>
                <Icon src={LockLogo} alt="Lock Icon" />
                <InputComponent
                    width={"320px"}
                    height={"30px"}
                    type="password"
                    placeholder="비밀번호"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </InputContainer>
            <OptionsContainer>
                <OptionButton onClick={handleJoinOpenPopup}>회원가입</OptionButton>
                <OptionButton onClick={handleOpenFindIdOrPassword}>ID/비번 찾기</OptionButton>
            </OptionsContainer>
            <LoginButton onClick={handleLogin}>로그인</LoginButton>
            {isJoinPopupOpen && (
                <Popup
                    isOpen={isJoinPopupOpen}
                    title="회원가입"
                    content={<Join onClose={handleJoinClosePopup} />}
                    onClose={handleJoinClosePopup}
                />
            )}
            {isFindIdOrPasswordOpen && (
                <Popup
                    isOpen={isFindIdOrPasswordOpen}
                    title="아이디/비밀번호 찾기"
                    content={<FindIdOrPassword />}
                    onClose={handleFindIdOrPasswordClosePopup}
                />
            )}
        </LoginContainer>
    );
};

const LoginContainer = styled.div`
    width: 350px;
    height: 180px;
    border: 1px solid #282c34;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
`;

const LoginImage = styled.img`
    width: 50px;
    margin-bottom: 10px;
    position: relative;
    right: 140px;
`;

const InputContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;

const Icon = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 10px;
`;

const OptionsContainer = styled.div`
    width: 350px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
`;

const OptionButton = styled.span`
    cursor: pointer;
    color: #282c34;
    margin-right: 10px;
`;

const LoginButton = styled.button`
    width: 100%;
    padding: 4px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
`;

export default Login;

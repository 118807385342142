import {Route, Routes} from "react-router-dom";
import MainPage from "../Main";
import ListPage from "../container/bbs/List";
import WritePage from "../container/bbs/Write";
import DetailPage from "../container/bbs/Detail";
import QnAList from "../container/bbs/QnAList";
import EditPage from "../container/bbs/Edit";
import QnADetailPage from "../container/bbs/QnADetail";
import SearchList from "../container/bbs/SearchList";
import MyWritePost from "../container/user/MyWritePost";

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/:gameKey" element={<ListPage/>}/>
            <Route path="/:gameKey/write" element={<WritePage/>}/>
            <Route path="/:gameKey/:postId" element={<DetailPage/>}/>
            <Route path="/edit/:gameKey/:postId" element={<EditPage />} />
            <Route path="/qna" element={<QnAList/>}/>
            <Route path="/qna/detail/:qnaId" element={<QnADetailPage />} />
            <Route path="/search-all" element={<SearchList />} />
            <Route path="/my-write-post/:userId" element={<MyWritePost />} />
        </Routes>
    )
}

export default Router;

import {useForm} from "react-hook-form";
import styled from "styled-components";
import React from "react";
import {registerCS} from "../../apis/QnA";
import {useAuthState} from "../../context/Auth";

const CustomerService = ({onClose}) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { user } = useAuthState();

    const onSubmit = async (data) => {
        await registerCS(user.email, data.title, data.content, onClose);
    }

    return (
        <FormContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputComponent
                    {...register("title", {
                        required: "제목은 필수 입력 항목입니다.",
                    })}
                    placeholder="제목을 입력해주세요."
                />
                {errors.title && <ErrorText>{errors.title.message}</ErrorText>}

                <TextAreaComponent
                    {...register("content", {required: "문의 내용을 입력해주세요."})}
                    placeholder="문의 내용을 입력해주세요."
                />
                {errors.content && <ErrorText>{errors.content.message}</ErrorText>}

                <SubmitButton type="submit">문의하기</SubmitButton>
            </form>
        </FormContainer>
    );
}

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const InputComponent = styled.input`
    width: 100%;
    padding: 8px;
    margin: 10px 0;
`;

const TextAreaComponent = styled.textarea`
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
`;

// TODO 공통컴포넌트로 export
const SubmitButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 5px;
`;

const ErrorText = styled.div`
    color: red;
    font-size: 14px;
    padding-left: 10;
`;

export default CustomerService;
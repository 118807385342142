import React from "react";
import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Lineage from "../assets/images/games/Untitled-2-01.png";
import Mue from "../assets/images/games/Untitled-2-02.png";
import CountryOfWind from "../assets/images/games/Untitled-2-03.png";
import Maple from "../assets/images/games/Untitled-2-04.png";
import Diablo from "../assets/images/games/Untitled-2-05.png";
import Iron from "../assets/images/games/Untitled-2-06.png";
import Dunpa from "../assets/images/games/Untitled-2-07.png";
import DarkYeden from "../assets/images/games/Untitled-2-08.png";
import StoneAge from "../assets/images/games/Untitled-2-09.png";
import Etc from "../assets/images/games/Untitled-2-10.png";
import CS from "../assets/images/games/Untitled-2-11.png";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MENU = [
  {
    name: "리니지",
    image: 'Lineage',
    path: "/lineage",
  },
  { name: "뮤", image: 'Mue', hoverOptions: {}, path: "/mue" },
  {
    name: "바람의나라",
    image: 'CountryOfWind',
    path: "/baram",
  },
  { name: "메이플", image: 'Maple', hoverOptions: {}, path: "/maple" },
  { name: "디아블로", image: 'Diablo', hoverOptions: {}, path: "/diablo" },
  { name: "아이온", image: 'Iron', hoverOptions: {}, path: "/aion" },
  { name: "다크에덴", image: 'DarkEden', hoverOptions: {}, path: "/darkeden" },
  { name: "던파", image: 'Dunpa', hoverOptions: {}, path: "/df" },
  { name: "스톤에이지", image: 'StoneAge', hoverOptions: {}, path: "/stoneage" },
  { name: "기타", image: 'Etc', hoverOptions: {}, path: "/etc" },
];

const MenuCarousel = () => {
  const items = MENU.map(menu => menu.name);

  // 현재 보여지는 메뉴 항목의 인덱스 시작점
  const [startIndex, setStartIndex] = useState(0);
  const navigate = useNavigate();

  // 현재 보여지는 메뉴 항목
  const visibleItems = items.slice(startIndex, startIndex + 5);

  // '이전' 버튼 클릭 핸들러
  const handlePrevious = () => {
    setStartIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? items.length - 5 : prevIndex - 1;
      return newIndex;
    });
  };

  // '다음' 버튼 클릭 핸들러
  const handleNext = () => {
    setStartIndex((prevIndex) => {
      const newIndex = prevIndex === items.length - 5 ? 0 : prevIndex + 1;
      return newIndex;
    });
  };

  // 아이템 클릭 핸들러
  const handleItemClick = (itemName) => {
    const menuItem = MENU.find(menu => menu.name === itemName);
    if (menuItem) {
      navigate(menuItem.path);
    }
  };

  return (
      <NavMenu>
        <FontAwesomeIcon icon={faAngleLeft} onClick={handlePrevious} />
        {visibleItems.map((item, index) => (
            <div key={index} onClick={() => handleItemClick(item)}>
              {item}
            </div>
        ))}
        <FontAwesomeIcon onClick={handleNext} icon={faAngleRight} />
      </NavMenu>
  );
};

export default MenuCarousel;

const NavMenu = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
  position: relative;

  > div:hover {
    background-color: white;
    color: black;
    font-weight: bold;
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../apis/Axios";
import TableRow from "../../components/common/TableRow";
import renderPagination from "../../components/RenderPagination";

const SearchList = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const rowsPerPage = 15;

    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const keyword = queryParams.get('keyword');
    const currentPage = parseInt(queryParams.get('page')) || 1;

    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const maxPageButtons = 10;
    const startPage = Math.floor((currentPage - 1) / maxPageButtons) * maxPageButtons + 1;
    const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    const handleClick = (pageNumber) => {
        navigate(`?page=${pageNumber}&keyword=${keyword}`);
    };

    const handlePrevPageGroup = () => {
        if (startPage > 1) {
            navigate(`?page=${startPage - 1}&keyword=${keyword}`);
        }
    };

    const handleNextPageGroup = () => {
        if (endPage < totalPages) {
            navigate(`?page=${endPage + 1}&keyword=${keyword}`);
        }
    };

    const handleFirstPage = () => {
        navigate(`?page=1&keyword=${keyword}`);
    };

    const handleLastPage = () => {
        navigate(`?page=${totalPages}&keyword=${keyword}`);
    };

    const fetchData = async () => {
        if (!keyword) return;

        setLoading(true);
        try {
            const response = await axiosInstance.get("/bbs/search-all", {
                params: { keyword, page: currentPage, rowsPerPage }
            });

            const data = response.data;
            setPosts(Array.isArray(data.content) ? data.content : []);
            setTotalRows(data.totalElements);
        } catch (error) {
            console.error('Error fetching data:', error);
            setPosts([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [keyword, currentPage]);

    const renderRows = () => {
        return posts.map((post, index) => (
            <TableRow
                key={post.id}
                index={index + 1}
                id={post.id}
                title={post.title}
                nickname={post.userNickname}
                postColor={post.postColor}
                writeDate={post.writeDate}
                watch={post.watch}
                postType={post.postType}
                gameKey={post.category}
                onPostUpdated={fetchData} // 리스트 업데이트 호출
            />
        ));
    };

    return (
        <ListBox>
            <h1>'{keyword}'에 대한 검색결과</h1>
            <DivideHr />
            <DataTable>
                <thead>
                <tr>
                    <Th>NO</Th>
                    <ThThumb>추천</ThThumb>
                    <ThTitle>제목</ThTitle>
                    <Th>닉네임</Th>
                    <Th>작성일</Th>
                    <Th>조회수</Th>
                </tr>
                </thead>
                <tbody>{loading ? <tr><Td colSpan={6}>Loading...</Td></tr> : renderRows()}</tbody>
            </DataTable>

            {renderPagination({
                startPage,
                endPage,
                currentPage,
                handleClick,
                handleFirstPage,
                handlePrevPageGroup,
                handleNextPageGroup,
                handleLastPage,
                totalPages
            })}
        </ListBox>
    );
};

const DivideHr = styled.hr``;

const ListBox = styled.div`
    width: 80%;
    margin: auto;
`;

const DataTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
`;

const ThThumb = styled.th`
    width: 10%;
    background-color: #f4f4f4;
    padding: 8px;
`;

const ThTitle = styled.th`
    width: 50%;
    background-color: #f4f4f4;
    padding: 8px;
`;

const Th = styled.th`
    width: 15%;
    background-color: #f4f4f4;
    padding: 8px;
`;

const Td = styled.td`
    padding: 8px;
    text-align: center;
`;

export default SearchList;

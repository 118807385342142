import styled from "styled-components";
import {useForm} from "react-hook-form";
import React, {useState} from "react";
import {ValidateEmail, VerifyEmailAndReturnId, ChangePasswordApi} from "../../apis/User";
import Swal from "sweetalert2";


const ChangePassword = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [sendEmail, setSendEmail] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const handleSendEmail = async (data) => {
        await VerifyEmailAndReturnId(data.id, data.email, setSendEmail, setVerificationSent);
        setSendEmail(true);
        setVerificationSent(true);
    };

    const handleVerifyCode = async (data) => {
        const isValidate = await ValidateEmail(data.email, data.otp);
        if (isValidate) {
            setIsVerified(true);
        } else {
            Swal.fire({
                icon: 'error',
                title: '에러',
                text: '인증번호가 유효하지 않습니다.',
            });
            setSendEmail(false);
            setVerificationSent(false);
            setIsVerified(false);
        }
    };

    const handleChangePassword = async (data) => {
        if (data.newPassword !== data.confirmPassword) {
            Swal.fire({
                icon: 'error',
                title: '에러',
                text: '비밀번호가 일치하지 않습니다.',
            });
            return;
        }
        await ChangePasswordApi(data.email, data.newPassword);
        Swal.fire({
            icon: 'success',
            title: '성공',
            text: '비밀번호가 성공적으로 변경되었습니다.',
        });
        reset();
        setSendEmail(false);
        setVerificationSent(false);
        setIsVerified(false);
    };

    return (
        <FormContainer>
            <form onSubmit={handleSubmit(isVerified ? handleChangePassword : (verificationSent ? handleVerifyCode : handleSendEmail))} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {!isVerified && (
                    <>
                        <InputComponent
                            {...register("id", {
                                required: "아이디는 필수 입력 항목입니다.",
                            })}
                            placeholder="가입하실 때 입력하신 아이디를 입력 해 주세요."
                            disabled={sendEmail}
                        />
                        {errors.id && <ErrorText>{errors.id.message}</ErrorText>}

                        <InputComponent
                            {...register("email", {
                                required: "이메일은 필수 입력 항목입니다.",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "유효한 이메일 주소를 입력해주세요."
                                }
                            })}
                            placeholder="가입하실 때 입력하신 이메일 주소를 입력 해 주세요."
                            disabled={sendEmail}
                        />
                        {errors.email && <ErrorText>{errors.email.message}</ErrorText>}

                        {verificationSent && (
                            <>
                                <InputComponent
                                    {...register("otp", { required: "인증번호를 입력해주세요." })}
                                    placeholder="인증번호를 입력하세요."
                                />
                                {errors.otp && <ErrorText>{errors.otp.message}</ErrorText>}
                            </>
                        )}
                    </>
                )}

                {isVerified && (
                    <>
                        <InputComponent
                            {...register("newPassword", { required: "새 비밀번호를 입력해주세요." })}
                            placeholder="새 비밀번호"
                            type="password"
                        />
                        {errors.newPassword && <ErrorText>{errors.newPassword.message}</ErrorText>}

                        <InputComponent
                            {...register("confirmPassword", { required: "새 비밀번호 확인을 입력해주세요." })}
                            placeholder="새 비밀번호 확인"
                            type="password"
                        />
                        {errors.confirmPassword && <ErrorText>{errors.confirmPassword.message}</ErrorText>}
                    </>
                )}

                <SubmitButton type="submit">{isVerified ? "비밀번호 변경" : (verificationSent ? "인증하기" : "인증번호 전송하기")}</SubmitButton>
            </form>
        </FormContainer>
    );
};

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const InputComponent = styled.input`
    width: 400px;
    padding: 8px;
    margin: 10px 0;
`;

const SubmitButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 5px;
`;

const ErrorText = styled.div`
    color: red;
    font-size: 14px;
`;

export default ChangePassword;
import React, {createContext, useReducer, useContext, useEffect} from 'react';
import {jwtDecode} from "jwt-decode";
import axiosInstance from "../apis/Axios";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                access_token: action.payload.access_token,
                refresh_token: action.payload.refresh_token,
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                access_token: null,
                refresh_token: null,
            };
        case 'UPDATE_USER':
            return {
                ...state,
                user: {...state.user, ...action.payload},
            };
        case 'UPDATE_POINTS':
            return {
                ...state,
                user: {...state.user, points: action.payload.points},
            };
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

const loadUserInfo = async (accessToken, dispatch) => {
    try {
        const decodedToken = jwtDecode(accessToken);
        const userInfoResponse = await axiosInstance.get(`/user/info?id=${decodedToken.id}`);
        const userInfo = {
            id: userInfoResponse.data.id,
            email: userInfoResponse.data.email,
            nickname: userInfoResponse.data.nickname,
            role: userInfoResponse.data.role,
            points: userInfoResponse.data.points || 0,
            imageUrl: userInfoResponse.data.imageUrl || '',
        };
        dispatch({
            type: 'LOGIN',
            payload: {user: userInfo, accessToken, refreshToken: localStorage.getItem('refresh_token')}
        });
    } catch (error) {
        console.error('Error loading user info:', error);
    }
}

export const AuthProvider = ({children}) => {
    const initialState = {
        isAuthenticated: false,
        user: null,
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
    };
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        const {access_token} = state;
        if (access_token) {
            loadUserInfo(access_token, dispatch);
        }
    }, [state.access_token]);

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
};

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);

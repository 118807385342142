import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {io} from "socket.io-client";
import {useNavigate} from "react-router-dom";
import PostBox from "../../components/common/Game/PostBox";
import Lineage from "../../assets/images/games/Untitled-2-01.png";
import Mue from "../../assets/images/games/Untitled-2-02.png";
import CountryOfWind from "../../assets/images/games/Untitled-2-03.png";
import Maple from "../../assets/images/games/Untitled-2-04.png";
import Diablo from "../../assets/images/games/Untitled-2-05.png";
import Iron from "../../assets/images/games/Untitled-2-06.png";
import Dunpa from "../../assets/images/games/Untitled-2-07.png";
import DarkYeden from "../../assets/images/games/Untitled-2-08.png";
import StonAge from "../../assets/images/games/Untitled-2-09.png";
import RealTime from "../../assets/images/realtime.png";

const RealTimePosts = () => {
    const [posts, setPosts] = useState({});
    const navigate = useNavigate();
    const categories = ["lineage", "mue", "baram", "maple", "aion", "darkeden", "df", "stoneage", "diablo"];

    useEffect(() => {
        let socketUrl;
        if (process.env.NODE_ENV === "production") {
            socketUrl = "wss://api.시즌프리.com:8000/posts";
        } else {
            socketUrl = "http://localhost:8000/posts";
        }

        const socket = io(socketUrl, {
            withCredentials: true,
            transports: ['websocket'],
            reconnection: true,
        });

        socket.on('connect', () => {
            // console.log('Connected to WebSocket server');
            categories.forEach(category => {
                socket.emit('requestLatestPostsByCategory', {category});
            });
        })

        socket.on('receiveLatestPostsByCategory', ({category, posts}) => {
            // console.log(`소켓으로 받은 데이터: ${category}: ${JSON.stringify(posts)}`);
            setPosts(prevPosts => ({
                ...prevPosts,
                [category]: posts
            }));
        });

        // Clean up on unmount
        return () => {
            socket.off('receiveLatestPostsByCategory');
            socket.close();
        };
    }, []);

    const postBoxes = [
        {gname: "리니지", gicon: Lineage, color: "black", category: "lineage"},
        {gname: "뮤", gicon: Mue, color: "red", category: "mue"},
        {gname: "바람의나라", gicon: CountryOfWind, color: "#1A66CC", category: "baram"},
        {gname: "메이플", gicon: Maple, color: "#FFBF00", category: "maple"},
        {gname: "아이온", gicon: Iron, color: "skyblue", category: "aion"},
        {gname: "다크에덴", gicon: DarkYeden, color: "#800000", category: "darkeden"},
        {gname: "던파", gicon: Dunpa, color: "#FF7F00", category: "df"},
        {gname: "스톤에이지", gicon: StonAge, color: "#FFD700", category: "stoneage"},
        {gname: "디아블로", gicon: Diablo, color: "#D2B48C", category: "diablo"},
    ];

    return (
        <>
            <RealTimeImage src={RealTime} alt={"실시간 게시물"}/>
            <GridContainer>
                {postBoxes.map(({gname, gicon, color, category}) => (
                    <PostBox key={category} posts={posts[category] || []} gname={gname} color={color}
                             category={category}/>
                ))}
            </GridContainer>
        </>
    );
};

const RealTimeImage = styled.img`
    width: 200px;
`;

const GridContainer = styled.div`
    width: 100%;
    max-width: 890px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 한 줄에 3개씩 배치
    gap: 10px;
    margin-bottom: 20px;
    //padding: 10px; 
`;


export default RealTimePosts;

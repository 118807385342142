import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faSignOutAlt, faCoins} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Popup from "../../components/common/Popup";
import StyledCalendar from "./Calendar";
import React, {useEffect, useState} from "react";
import {faCalendarCheck} from "@fortawesome/free-regular-svg-icons";
import {useAuthDispatch, useAuthState} from "../../context/Auth";
import {useNavigate} from "react-router-dom";
import MyInfo from "./MyInfo";
import axiosInstance from "../../apis/Axios";

const AfterLogin = ({ nickname, points, imageUrl }) => {
    const { user } = useAuthState();
    const dispatch = useAuthDispatch();
    const navigate = useNavigate();

    const onLogout = () => {
        dispatch({ type: 'LOGOUT' });
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        Swal.fire({
            title: '로그아웃',
            text: '로그아웃 처리되었습니다.',
            icon: 'success',
        }).then(() => {
            window.location.reload();
        });
    };

    const [isAttendancePopupOpen, setIsAttendancePopupOpen] = useState(false);
    const [isMyPagePopupOpen, setIsMyPagePopupOpen] = useState(false);

    const handleAttendanceOpenPopup = () => {
        setIsAttendancePopupOpen(true);
    };

    const handleAttendanceClosePopup = () => {
        setIsAttendancePopupOpen(false);
    };

    const handleMyPageOpenPopup = () => {
        setIsMyPagePopupOpen(true);
    };

    const handleMyPageClosePopup = () => {
        setIsMyPagePopupOpen(false);
    };

    const myWritePageNavigate = (userId) => {
        navigate(`/my-write-post/${userId}`);
    };

    const updateUserInfo = (newInfo) => {
        dispatch({ type: 'UPDATE_USER', payload: newInfo });
    };

    return (
        <LoginContainer>
            <IconAndNicknameBox>
                <ProfileIcon>
                    {imageUrl ? (
                        <ProfileImage src={imageUrl} alt='user profile' />
                    ) : (
                        <FontAwesomeIcon icon={faUser} size='lg' />
                    )}
                </ProfileIcon>
                <Nickname>{nickname || '알수없음'}</Nickname>
            </IconAndNicknameBox>

            <AccountAndAttendance>
                <SecondItem onClick={() => myWritePageNavigate(user.id)}>내 작성글</SecondItem>
                <Separator>|</Separator>
                <SecondItem onClick={handleMyPageOpenPopup}>내정보</SecondItem>
                <Separator>|</Separator>
                <CalendarCheck onClick={handleAttendanceOpenPopup}>
                    <FontAwesomeIcon icon={faCalendarCheck} />
                    <AttendanceCheck>출석체크</AttendanceCheck>
                </CalendarCheck>
            </AccountAndAttendance>

            <PointAndLogout>
                <PointBox>
                    <FontAwesomeIcon icon={faCoins} style={{ width: '25px', height: '25px' }} />
                    <Point>
                        포인트 <RedText>{points ? `${points}` : '0'}</RedText>
                    </Point>
                </PointBox>

                <LogoutBox onClick={onLogout}>
                    <Logout>로그아웃</Logout>
                </LogoutBox>
            </PointAndLogout>

            {isAttendancePopupOpen && (
                <Popup
                    isOpen={isAttendancePopupOpen}
                    title='출석부'
                    content={<StyledCalendar />}
                    onClose={handleAttendanceClosePopup}
                />
            )}
            {isMyPagePopupOpen && (
                <Popup
                    isOpen={isMyPagePopupOpen}
                    title='내정보'
                    content={<MyInfo setUserInfo={updateUserInfo} />}
                    onClose={handleMyPageClosePopup}
                />
            )}
        </LoginContainer>
    );
};

const LoginContainer = styled.div`
    width: 350px;
    height: 150px;
    padding: 10px;
    border: 1px solid #282c34;
    margin-top: 5px;
`;

const IconAndNicknameBox = styled.div`
    display: flex;
    align-items: center;
`;

const ProfileImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const ProfileIcon = styled.div`
    width: 60px;
    height: 60px;
    background-color: gray;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right: 10px;
    margin-left: 10px;

    svg {
        width: 100%;
        height: auto;
        color: white;
    }
`;

const Nickname = styled.div`
    font-size: 30px;
    font-weight: bold;
    margin-left: 70px;
`;

const AccountAndAttendance = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: end; /* 좌측 정렬 */
    padding: 0 10px; /* 좌우 패딩 추가 */
`;

const SecondItem = styled.div`
    cursor: pointer;
`;

const CalendarCheck = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center; // 아이콘과 텍스트를 세로 중앙 정렬
    margin-left: 10px;
`;

const AttendanceCheck = styled.span`
    margin-left: 2px;
`;

const Separator = styled.span`
    margin: 0 10px; /* 좌우 간격 추가 */
    color: #282c34;
`;

const PointAndLogout = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
`;

const PointBox = styled.div`
    display: flex;
    align-items: center;
`;

const Point = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
`

const RedText = styled.span`
    font-size: 20px;
    font-weight: bold;
    color: red;
`;

const LogoutBox = styled.div`
    width: 100px;
    height: 30px;
    background-color: #282c34;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
`;

const Logout = styled.div`
    color: aliceblue;
    font-weight: bold;
`;

export default AfterLogin;

import React, { useState } from 'react';
import axiosInstance from '../../apis/Axios';
import styled from "styled-components";
import Swal from "sweetalert2";

const ThumbsUp = ({ postId, onClose, onColorChange }) => {
    const [selectedColor, setSelectedColor] = useState('');

    const handleColorChange = (e) => {
        setSelectedColor(e.target.value);
    };

    const handleRecommendSubmit = async () => {
        try {
            await axiosInstance.post('/admin/thumbs-up', { postId, color: selectedColor });
            Swal.fire({
                title: "성공",
                text: "추천이 반영되었습니다.",
                icon: "success"
            });
            onColorChange(selectedColor);
            onClose();
        } catch (error) {
            console.error('추천 요청 중 오류 발생:', error);
            Swal.fire({
                title: "에러",
                text: "추천 반영 중 에러가 발생하였습니다.",
                icon: "error"
            });
        }
    };

    return (
        <>
            <div>
                <Label>
                    <StyledSelect value={selectedColor} onChange={handleColorChange}>
                        <option value="">색상을 선택하세요</option>
                        <option value="#00ffff">하늘색</option>
                        <option value="#ffd700">노란색</option>
                        <option value="#3cf73c">형광색</option>
                        <option value="#ff7f00">적색</option>
                    </StyledSelect>
                </Label>
            </div>
            <SubmitButton onClick={handleRecommendSubmit}>추천</SubmitButton>
        </>
    );
};

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #6200ea;
`;

const StyledSelect = styled.select`
    margin-top: 8px;
    padding: 8px;
    border: 2px solid #6200ea;
    border-radius: 4px;
    background: white;
    font-size: 16px;
    color: #6200ea;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='rgba(98,0,234,1)' d='M7 10l5 5 5-5z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 16px 16px;
    margin-bottom: 15px;

    &:focus {
        outline: none;
        box-shadow: 0 0 4px rgba(98, 0, 234, 0.5);
    }
`;


const SubmitButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 5px;
`;

export default ThumbsUp;

import axios from 'axios';
import Swal from 'sweetalert2';

let axiosUrl;
if (process.env.NODE_ENV === "production") {
    axiosUrl = "https://api.시즌프리.com:8000";
} else {
    axiosUrl = "http://localhost:8000";
}

// Axios instance configuration
const axiosInstance = axios.create({
    baseURL: axiosUrl, // Base URL of the API
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add token to request headers if available
axiosInstance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
});

// axios interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const {config, response: {status}} = error;
        const originalRequest = config;

        if (status === 401) {
            const refreshToken = localStorage.getItem('refresh_token');

            if (!refreshToken) {
                Swal.fire({
                    icon: 'error',
                    title: '인증오류',
                    text: '다시 로그인 해 주세요.',
                });
                return Promise.reject(error);
            }

            try {
                // Attempt to get a new access token using the refresh token
                const {data} = await axios.post(`${axiosUrl}/user/new-access-token`, {refreshToken});

                const newAccessToken = data.access_token;
                const newRefreshToken = data.refresh_token;

                // Store the new tokens
                localStorage.setItem('access_token', newAccessToken);
                localStorage.setItem('refresh_token', newRefreshToken);

                // Update the authorization header and retry the original request
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                return axios(originalRequest);
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    title: '로그인이 만료되었습니다.',
                    text: '다시 로그인 해 주세요.',
                });
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                return Promise.reject(err);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;

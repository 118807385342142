import {useForm} from "react-hook-form";
import styled from "styled-components";
import {FaXmark} from "react-icons/fa6";
import {FaPen, FaPlus, FaUser} from "react-icons/fa";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ProfileImageUrl} from "../../apis/User";
import axiosInstance from "../../apis/Axios";
import Swal from "sweetalert2";
import {useAuthDispatch, useAuthState} from "../../context/Auth";
import Popup from "../../components/common/Popup";
import ChangePassword from "./ChangePassword";

const MyInfo = () => {
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const inputRef = useRef(null);
    const [isPasswordChange, setIsPasswordChange] = useState(false);
    const { user } = useAuthState();
    const [userInfo, setUserInfo] = useState({});
    const [nickname, setNickname] = useState('');
    const [errors, setErrors] = useState({ nickname: '' });
    const dispatch = useAuthDispatch();

    const fetchUserInfo = async () => {
        try {
            const response = await axiosInstance.get(`/user/info-all/${user.id}`);
            const userData = response.data;
            setUserInfo(userData);
            setProfileImageUrl(userData.imageUrl);
            setNickname(userData.nickname); // 기본값 설정
        } catch (error) {
            console.error('Error fetching user info:', error);
            Swal.fire({
                icon: "error",
                title: "에러",
                text: error.response.data.message
            });
        }
    };

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const onUploadImageButtonClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, []);

    const handlePasswordChange = () => {
        setIsPasswordChange(true);
    };

    const handleBack = () => {
        setIsPasswordChange(false);
    };

    const handleFileChange = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await ProfileImageUrl(formData);

                if (response.status !== 201) {
                    throw new Error('서버로부터 응답을 받는데 실패했습니다.');
                }

                const result = response.data;
                setProfileImageUrl(result.imageUrl);
                console.log(`서버로부터 반환된 이미지 URL: ${result.imageUrl}`);
            } catch (error) {
                console.error('파일 업로드 오류:', error);
            }
        } else {
            console.log('선택된 파일 없음');
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = { nickname: '' };

        if (!nickname.trim()) {
            newErrors.nickname = '닉네임을 입력해 주세요.';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const response = await axiosInstance.patch(`/user/update-info/${user.id}`, {
                nickname,
                imageUrl: profileImageUrl
            });

            // Update the user context
            dispatch({
                type: 'LOGIN',
                payload: { user: { ...user, nickname, imageUrl: profileImageUrl } }
            });

            Swal.fire({
                icon: 'success',
                title: '성공',
                text: '정보가 성공적으로 변경되었습니다.',
            });
            fetchUserInfo(); // 성공 시 사용자 정보를 다시 불러옵니다.
        } catch (error) {
            let errorMessage = '정보 변경 중 오류가 발생했습니다.';
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            Swal.fire({
                icon: 'error',
                title: '에러',
                text: errorMessage,
            });
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <ProfileIconRow>
                {profileImageUrl ? (
                    <ProfileIcon hasImg={!!profileImageUrl}>
                        <FaXmark
                            className="imgHandler clear"
                            onClick={() => {
                                setProfileImageUrl('');
                            }}
                        />
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                overflow: "hidden",
                                borderRadius: "50%",
                            }}
                        >
                            <img src={profileImageUrl} alt="Profile" />
                        </div>
                    </ProfileIcon>
                ) : (
                    <ProfileIcon>
                        <FaPen
                            className="imgHandler add"
                            onClick={onUploadImageButtonClick}
                        />
                        <FaUser className="user" />
                    </ProfileIcon>
                )}
                <input
                    id="profileImageInput"
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
            </ProfileIconRow>

            <InputComponent
                value={userInfo.userId}
                disabled
            />

            <ChangePasswordButton onClick={handlePasswordChange}>
                비밀번호 변경하기
            </ChangePasswordButton>

            <InputComponent
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                placeholder="변경할 닉네임을 입력 해 주세요."
            />
            {errors.nickname && <ErrorText>{errors.nickname}</ErrorText>}

            <InputComponent
                value={userInfo.email}
                type="email"
                disabled
            />

            <SubmitButton type="submit">정보 변경하기</SubmitButton>
            {isPasswordChange && (
                <Popup
                    isOpen={isPasswordChange}
                    title="비밀번호 변경"
                    content={<ChangePassword />}
                    onClose={handleBack}
                />
            )}
        </form>
    );
}

const ProfileIconRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ProfileIcon = styled.div`
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    padding: ${(props) => (props.hasImg ? "0" : "15px")};
    background-color: gray;
    border-radius: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    svg.user {
        width: 100%;
        height: auto;
        color: white;
    }

    svg.imgHandler {
        position: absolute;
        top: 0;
        right: -5px;
        color: white;
        border-radius: 50%;
        width: 30%;
        height: 30%;
        padding: 1px;
        z-index: 1;
        cursor: pointer;

        &.add {
            background-color: #70e07f;
        }

        &.clear {
            background-color: #f55151;
        }
    }
`;

const SubmitButton = styled.button`
    width: 90%;
    padding: 10px;
    margin: 20px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 5px;
`;

const ChangePasswordButton = styled.button`
    width: 95%;
    height: 90%;
    border: #282c34 1px solid;
    margin: 10px;
    padding: 8px;
`;

const InputComponent = styled.input`
    width: 95%;
    margin: 10px;
    padding: 8px;
`;

const ErrorText = styled.p`
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
`;

export default MyInfo;
import styled from "styled-components";
import logoImage from "../assets/images/logo2.png";
import Lineage from "../assets/images/games/Untitled-2-01.png";
import Mue from "../assets/images/games/Untitled-2-02.png";
import CountryOfWind from "../assets/images/games/Untitled-2-03.png";
import Maple from "../assets/images/games/Untitled-2-04.png";
import Diablo from "../assets/images/games/Untitled-2-05.png";
import Iron from "../assets/images/games/Untitled-2-06.png";
import Dunpa from "../assets/images/games/Untitled-2-07.png";
import DarkEden from "../assets/images/games/Untitled-2-08.png";
import StoneAge from "../assets/images/games/Untitled-2-09.png";
import Etc from "../assets/images/games/Untitled-2-10.png";
import CS from "../assets/images/games/Untitled-2-11.png";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Popup from "./common/Popup";
import CustomerService from "../container/user/CustomerService";
import {useAuthState} from "../context/Auth";
import {faHouse, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MenuCarousel from "./MenuCarousel";
import Join from "../container/user/Join";
import FindIdOrPassword from "../container/user/FindIdOrPassword";
import PointAllocationComponent from "./common/PointAllocationComponent";

const Header = () => {
    const navigate = useNavigate();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPointPopupOpen, setIsPointPopupOpen] = useState(false);
    const [isJoinPopupOpen, setIsJoinPopupOpen] = useState(false);
    const [isFindPopupOpen, setIsFindPopupOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const {user} = useAuthState();

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearchClick = () => {
        handleSearch();
    };

    const handleSearch = () => {
        if (searchTerm.trim()) {
            navigate(`/search-all?keyword=${searchTerm}`);
        }
    };

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handlePointOpenPopup = () => {
        setIsPointPopupOpen(true);
    }

    const handlePointClosePopup = () => {
        setIsPointPopupOpen(false);
    }

    const handleJoinOpenPopup = () => {
        setIsJoinPopupOpen(true);
    }

    const handleJoinClosePopup = () => {
        setIsJoinPopupOpen(false);
    }

    const handleFindOpenPopup = () => {
        setIsFindPopupOpen(true);
    }

    const handleFindClosePopup = () => {
        setIsFindPopupOpen(false);
    }

    const navigationMain = () => {
        navigate("/");
    };

    const handleNavigate = (path) => {
        navigate(path);
    };

    const handleOptionClick = (option) => {
        switch (option) {
            case "리니지":
                handleNavigate("/lineage");
                break;
            case "리니지2":
                handleNavigate("/lineage_2");
                break;
            case "리마스터":
                handleNavigate("/remaster");
                break;
            default:
                break;
        }
    };

    const MenuValues = [
        {
            name: "리니지",
            image: Lineage,
            path: "/lineage",
            hoverOptions: {
                option1: "리니지",
                option2: "리니지2",
                option3: "리마스터",
            },
            onOptionClick: handleOptionClick,
        },
        {name: "뮤", image: Mue, hoverOptions: {}, path: "/mue"},
        {
            name: "바람의나라",
            image: CountryOfWind,
            hoverOptions: {},
            path: "/baram",
        },
        {name: "메이플", image: Maple, hoverOptions: {}, path: "/maple"},
        {name: "디아블로", image: Diablo, hoverOptions: {}, path: "/diablo"},
        {name: "아이온", image: Iron, hoverOptions: {}, path: "/aion"},
        {name: "다크에덴", image: DarkEden, hoverOptions: {}, path: "/darkeden"},
        {name: "던파", image: Dunpa, hoverOptions: {}, path: "/df"},
        {name: "스톤에이지", image: StoneAge, hoverOptions: {}, path: "/stoneage"},
        {name: "기타", image: Etc, hoverOptions: {}, path: "/etc"},
    ];

    if (user) {
        MenuValues.push({
            name: user.role === "ADMIN" ? "문의내용" : "고객센터",
            image: CS,
            hoverOptions: {},
            onClick: user.role === "ADMIN"
                ? () => handleNavigate("/qna")
                : handleOpenPopup,
        });
        if (user.role === "ADMIN") {
            MenuValues.push({
                name: "포인트 부여",
                onClick: handlePointOpenPopup
            });
        }
    }

    return (
        <>
            <SearchContainer>
                <QuickMenuBox>
                    {user ? (
                        <div style={{display: "flex"}}>

                            <QuickItem onClick={handleOpenPopup}>1:1 문의</QuickItem>
                        </div>
                    ) : (
                        <>
                            <QuickItem onClick={handleJoinOpenPopup}>회원가입</QuickItem>
                            <Separator>|</Separator>
                            <QuickItem onClick={handleFindOpenPopup}>찾기</QuickItem>
                        </>
                    )}
                </QuickMenuBox>
                <LogoImage onClick={navigationMain}/>
                <SearchBox>
                    <SearchBar className="search_bar">
                        <SearchInput
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyPress}
                            placeholder="전체 검색기능"
                        />
                        <SearchButton onClick={handleSearchClick}>
                            <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </SearchButton>
                    </SearchBar>
                    <MenuCarousel/>
                </SearchBox>
            </SearchContainer>

            <MainHeader>
                <HomeIcon onClick={navigationMain}>
                    <FontAwesomeIcon icon={faHouse}/>
                </HomeIcon>
                {MenuValues.map((game) => (
                    <GameMenu
                        key={game.name}
                        onClick={() => game.onClick ? game.onClick() : handleNavigate(game.path)}
                    >
                        <GameImage
                            style={{backgroundImage: `url(${game.image})`}}
                        />
                        <GameName>
                            {game.name}
                        </GameName>
                        {game.hoverOptions && Object.keys(game.hoverOptions).length > 0 && (
                            <DropdownContent className="dropdown-content">
                                {Object.entries(game.hoverOptions).map(([key, value]) => (
                                    <DropdownItem
                                        key={key}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            game.onOptionClick(value);
                                        }}
                                    >
                                        {value}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        )}
                    </GameMenu>
                ))}
                {isPopupOpen && (
                    <Popup
                        isOpen={isPopupOpen}
                        title={"고객센터에 문의하기"}
                        content={<CustomerService/>}
                        onClose={handleClosePopup}
                    />
                )}
                {isPointPopupOpen && (
                    <Popup
                        isOpen={isPointPopupOpen}
                        title={"포인트 부여"}
                        content={<PointAllocationComponent/>}
                        onClose={handlePointClosePopup}
                    />
                )}
                {isJoinPopupOpen && (
                    <Popup
                        isOpen={isJoinPopupOpen}
                        title={"회원가입"}
                        content={<Join/>}
                        onClose={handleJoinClosePopup}
                    />
                )}
                {isFindPopupOpen && (
                    <Popup
                        isOpen={isFindPopupOpen}
                        title={"아이디/비밀번호 찾기"}
                        content={<FindIdOrPassword/>}
                        onClose={handleFindClosePopup}
                    />
                )}
            </MainHeader>
        </>
    );
};

const HideH1 = styled.h1`
    font-size: 15px;
`;

const SearchContainer = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const QuickMenuBox = styled.div`
    position: absolute;
    top: 0;
    right: 10px;
`;

const QuickItem = styled.span`
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
`;

const SearchBox = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SearchBar = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const SearchInput = styled.input`
    flex: 1;
    height: 40px;
    border: 1px solid #282c34;
    padding: 0 10px;
`;

const SearchButton = styled.button`
    width: 50px;
    height: 40px;
    background: black;
    color: white;
    border: none;
    cursor: pointer;
`;

const HomeIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 10px;
`;

const LogoImage = styled.div`
    background-image: url(${logoImage});
    width: 200px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    bottom: 15px;
`;

const Separator = styled.span`
    margin: 0 10px; /* 좌우 간격 추가 */
    color: #282c34;
`;

const MainHeader = styled.header`
    width: 100%;
    height: 70px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    color: white;
    background-color: black;
`;

const GameMenu = styled.div`
    flex: 0 1 auto;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: white;
        color: black;
        font-weight: bold;

        > div.dropdown-content {
            display: block;
            background-color: black;
        }
    }
`;

const GameImage = styled.div`
    width: 40px;
    height: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 0 5px;
`;

const DropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 0;
    top: 100%;
`;

const DropdownItem = styled.div`
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    border-bottom: gray;
`;

const GameName = styled.div`
    font-weight: bold;
`;

export default Header;

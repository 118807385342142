import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import Login from "./container/user/Login";
import RealTimePosts from "./container/bbs/RealTimePosts";
import AfterLogin from "./container/user/AfterLogin";
import { useAuthState } from "./context/Auth";
import axiosInstance from "./apis/Axios";
import RealTimeComment from "./container/bbs/RealTimeComment";
import Swal from "sweetalert2";
import Popup from "./components/common/Popup";
import SizeBox from "./components/common/SizeBox";
import BannerAdd from "./container/bbs/BannerAdd";

const MainPage = () => {
  const { isAuthenticated, user } = useAuthState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);

  const BannerArray120x500One = [
    { id: 'banner1', name: '광고베너1', width: '120px', height: '500px', order: 1, image: null },
    { id: 'banner2', name: '광고베너2', width: '120px', height: '500px', order: 2, image: null },
    { id: 'banner3', name: '광고베너3', width: '120px', height: '500px', order: 3, image: null },
  ];

  const BannerArray120x500Two = [
    { id: 'banner1', name: '광고베너1', width: '120px', height: '500px', order: 4, image: null },
    { id: 'banner2', name: '광고베너2', width: '120px', height: '500px', order: 5, image: null },
    { id: 'banner3', name: '광고베너3', width: '120px', height: '500px', order: 6, image: null },
  ];

  const BannerArray350x180 = [
    { id: 'banner1', name: '광고베너1', width: '350px', height: '180px', order: 1, image: null },
    { id: 'banner2', name: '광고베너2', width: '350px', height: '180px', order: 2, image: null },
    { id: 'banner3', name: '광고베너3', width: '350px', height: '180px', order: 3, image: null },
  ];

  const BannerArray895x100 = [
    { id: 'banner1', name: '광고베너1', width: '895px', height: '100px', order: 1, image: null },
    { id: 'banner2', name: '광고베너2', width: '895px', height: '100px', order: 2, image: null },
    { id: 'banner3', name: '광고베너3', width: '895px', height: '100px', order: 3, image: null },
    { id: 'banner4', name: '광고베너4', width: '895px', height: '100px', order: 4, image: null },
    { id: 'banner5', name: '광고베너5', width: '895px', height: '100px', order: 5, image: null },
    { id: 'banner6', name: '광고베너6', width: '895px', height: '100px', order: 6, image: null },
    { id: 'banner7', name: '광고베너7', width: '895px', height: '100px', order: 7, image: null },
    // { id: 'banner8', name: '광고베너8', width: '895px', height: '100px', order: 8, image: null },
    // { id: 'banner9', name: '광고베너9', width: '895px', height: '100px', order: 9, image: null },
  ];

  const BannerArray120x500Three = [
    { id: 'banner1', name: '광고베너1', width: '120px', height: '500px', order: 7, image: null },
    { id: 'banner2', name: '광고베너2', width: '120px', height: '500px', order: 8, image: null },
    { id: 'banner3', name: '광고베너3', width: '120px', height: '500px', order: 9, image: null },
  ];

  const BannerArray120x500Four = [
    { id: 'banner1', name: '광고베너1', width: '120px', height: '500px', order: 10, image: null },
    { id: 'banner2', name: '광고베너2', width: '120px', height: '500px', order: 11, image: null },
    { id: 'banner3', name: '광고베너3', width: '120px', height: '500px', order: 12, image: null },
  ];

  const [banners, setBanners] = useState({
    BannerArray120x500One,
    BannerArray120x500Two,
    BannerArray350x180,
    BannerArray895x100,
    BannerArray120x500Three,
    BannerArray120x500Four,
  });

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoggedIn(true);
      setUserInfo(user);
    }
  }, [isAuthenticated, user]);

  const fetchBanners = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/banner/');
      const fetchedBanners = response.data;

      if (Array.isArray(fetchedBanners)) {
        setBanners(prevBanners => {
          const updatedBanners = { ...prevBanners };

          fetchedBanners.forEach(banner => {
            if (banner.width === '120px' && banner.height === '500px') {
              if (banner.orderNo >= 1 && banner.orderNo <= 3) {
                updatedBanners.BannerArray120x500One[banner.orderNo - 1].image = banner.bannerImageUrl;
                updatedBanners.BannerArray120x500One[banner.orderNo - 1].bannerClickUrl = banner.bannerClickUrl;
              } else if (banner.orderNo >= 4 && banner.orderNo <= 6) {
                updatedBanners.BannerArray120x500Two[banner.orderNo - 4].image = banner.bannerImageUrl;
                updatedBanners.BannerArray120x500Two[banner.orderNo - 4].bannerClickUrl = banner.bannerClickUrl;
              } else if (banner.orderNo >= 7 && banner.orderNo <= 9) {
                updatedBanners.BannerArray120x500Three[banner.orderNo - 7].image = banner.bannerImageUrl;
                updatedBanners.BannerArray120x500Three[banner.orderNo - 7].bannerClickUrl = banner.bannerClickUrl;
              } else if (banner.orderNo >= 10 && banner.orderNo <= 12) {
                updatedBanners.BannerArray120x500Four[banner.orderNo - 10].image = banner.bannerImageUrl;
                updatedBanners.BannerArray120x500Four[banner.orderNo - 10].bannerClickUrl = banner.bannerClickUrl;
              }
            } else if (banner.width === '350px' && banner.height === '180px') {
              updatedBanners.BannerArray350x180[banner.orderNo - 1].image = banner.bannerImageUrl;
              updatedBanners.BannerArray350x180[banner.orderNo - 1].bannerClickUrl = banner.bannerClickUrl;
            } else if (banner.width === '895px' && banner.height === '100px') {
              updatedBanners.BannerArray895x100[banner.orderNo - 1].image = banner.bannerImageUrl;
              updatedBanners.BannerArray895x100[banner.orderNo - 1].bannerClickUrl = banner.bannerClickUrl;
            }
          });

          return updatedBanners;
        });
      } else {
        console.error('배너 데이터가 배열 형식이 아닙니다.');
      }
    } catch (error) {
      console.error('배너 데이터를 가져오는 중 오류 발생:', error);
    }
  }, []);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  const handleOpenPopup = (banner) => {
    setSelectedBanner(banner);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedBanner(null);
  };

  const handlePopupSubmit = async (imageFile, clickUrl) => {
    const formData = new FormData();
    if (imageFile) {
      formData.append('file', imageFile);
    }
    formData.append('orderNo', selectedBanner.order);
    formData.append('width', selectedBanner.width);
    formData.append('height', selectedBanner.height);
    formData.append('bannerClickUrl', clickUrl);

    try {
      const response = await axiosInstance.post(`/banner`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire({
        title: "성공",
        text: "베너 이미지 등록에 성공하였습니다!",
        icon: "success"
      });

      handleClosePopup();
      fetchBanners(); // 배너 데이터를 다시 가져옵니다.
    } catch (error) {
      console.error('배너 등록 중 오류 발생:', error);
      Swal.fire({
        icon: "error",
        title: "에러",
        text: "베너 등록 중 오류가 발생했습니다.",
      });
    }
  };

  return (
      <MainContainer>
        <FirstContainer>
          {banners.BannerArray120x500One.map((banner) => (
              <SizeBox
                  key={banner.id}
                  width={banner.width}
                  height={banner.height}
                  order={banner.order}
                  initialImage={banner.image}
                  bannerClickUrl={banner.bannerClickUrl}
                  onClick={() => handleOpenPopup(banner)}
              />
          ))}
        </FirstContainer>

        <FirstContainer>
          {banners.BannerArray120x500Two.map((banner) => (
              <SizeBox
                  key={banner.id}
                  width={banner.width}
                  height={banner.height}
                  order={banner.order}
                  initialImage={banner.image}
                  bannerClickUrl={banner.bannerClickUrl}
                  onClick={() => handleOpenPopup(banner)}
              />
          ))}
        </FirstContainer>

        <SecondContainer>
          {isLoggedIn ? (
              <AfterLogin {...userInfo} />
          ) : (
              <Login setIsLoggedIn={setIsLoggedIn} setUserInfo={setUserInfo} />
          )}
          <RealTimeComment />
          {banners.BannerArray350x180.map((banner) => (
              <SizeBox
                  key={banner.id}
                  width={banner.width}
                  height={banner.height}
                  order={banner.order}
                  initialImage={banner.image}
                  bannerClickUrl={banner.bannerClickUrl}
                  onClick={() => handleOpenPopup(banner)}
              />
          ))}
        </SecondContainer>

        <ThirdContainer>
          <div>
            {banners.BannerArray895x100.map((banner) => (
                <SizeBox
                    key={banner.id}
                    width={banner.width}
                    height={banner.height}
                    order={banner.order}
                    initialImage={banner.image}
                    bannerClickUrl={banner.bannerClickUrl}
                    onClick={() => handleOpenPopup(banner)}
                >
                  {banner.name}
                </SizeBox>
            ))}
            <RealTimePosts />
            {/*{banners.BannerArray895x100.slice(3).map((banner) => (*/}
            {/*    <SizeBox*/}
            {/*        key={banner.id}*/}
            {/*        width={banner.width}*/}
            {/*        height={banner.height}*/}
            {/*        order={banner.order}*/}
            {/*        initialImage={banner.image}*/}
            {/*        bannerClickUrl={banner.bannerClickUrl}*/}
            {/*        onClick={() => handleOpenPopup(banner)}*/}
            {/*    >*/}
            {/*      {banner.name}*/}
            {/*    </SizeBox>*/}
            {/*))}*/}
          </div>
        </ThirdContainer>

        <FourthContainer>
          {banners.BannerArray120x500Three.map((banner) => (
              <SizeBox
                  key={banner.id}
                  width={banner.width}
                  height={banner.height}
                  order={banner.order}
                  initialImage={banner.image}
                  bannerClickUrl={banner.bannerClickUrl}
                  onClick={() => handleOpenPopup(banner)}
              />
          ))}
        </FourthContainer>

        <FourthContainer>
          {banners.BannerArray120x500Four.map((banner) => (
              <SizeBox
                  key={banner.id}
                  width={banner.width}
                  height={banner.height}
                  order={banner.order}
                  initialImage={banner.image}
                  bannerClickUrl={banner.bannerClickUrl}
                  onClick={() => handleOpenPopup(banner)}
              />
          ))}
        </FourthContainer>

        <Popup
            isOpen={isPopupOpen}
            title="배너 이미지 등록"
            content={<BannerAdd onSubmit={handlePopupSubmit} />}
            onClose={handleClosePopup}
        />
      </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
`;

const FirstContainer = styled.div`
  margin-right: 10px;
`;

const SecondContainer = styled.div`
  margin-right: 10px;
`;

const ThirdContainer = styled.div`
  margin-right: 10px;
`;

const FourthContainer = styled.div`
  margin-right: 10px;
`;

export default MainPage;

import { createGlobalStyle } from "styled-components";
import OnemobileRegular from '../assets/fonts/ONE-Mobile-Title.ttf';

const GlobalStyle = createGlobalStyle`

    html {
        overflow-y: scroll; /* 항상 스크롤바 공간 예약 */
    }
    
    a{
        text-decoration: none;
        color: inherit;
    }
    
    @font-face {
        font-family: 'OnemobileRegular';
        src: url(${OnemobileRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    * {
        font-family: 'OnemobileRegular', sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        font-family: 'OnemobileRegular', sans-serif;
    }

    ol, ul{
        list-style: none;
    }
    button {
        border: 0;
        background: transparent;
        cursor: pointer;
    }
  .main-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
  }
`;

export default GlobalStyle;

import styled from "styled-components";
import React, {useState} from "react";
import {useAuthState} from "../../context/Auth";
import axiosInstance from "../../apis/Axios";
import Swal from "sweetalert2";

const PointAllocationComponent = () => {
    const [nickname, setNickname] = useState('');
    const [points, setPoints] = useState(null);
    const {user} = useAuthState();

    const handleNicknameChange = (e) => {
        setNickname(e.target.value);
    };

    const handlePointsChange = (e) => {
        setPoints(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            const response = await axiosInstance.post('/user/point-allocation', {
                adminId: user.id, // 관리자의 ID를 설정해주세요
                nickname,
                point: points
            });

            if (response.status === 200) {
                Swal.fire({
                    title: "성공",
                    text: "포인트가 성공적으로 부여되었습니다!",
                    icon: "success"
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: error.response?.data?.message || "포인트 부여 중 오류가 발생했습니다.",
            });
        }
    }

    return (
        <>
            <form>
                <label style={{color: "black"}}>
                    포인트를 부여 할 닉네임을 입력 해 주세요
                    <InputComponent
                        type={"text"}
                        placeholder="닉네임"
                        value={nickname}
                        onChange={handleNicknameChange}
                    />
                </label>
                <label style={{color: "black"}}>
                    부여 할 포인트를 입력 해 주세요
                    <InputComponent
                        type={"number"}
                        placeholder="숫자만 입력 가능"
                        value={points}
                        onChange={handlePointsChange}
                    />
                </label>
            </form>
            <ButtonContainer>
                <SubmitButton onClick={handleSubmit}>전송하기</SubmitButton>
            </ButtonContainer>
        </>
    );
}

const InputComponent = styled.input`
    width: 95%;
    margin: 10px;
    padding: 8px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const SubmitButton = styled.button`
    background-color: #4CAF50;
    width: 100%;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
`;

export default PointAllocationComponent;
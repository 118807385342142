import styled from "styled-components";
import {useEffect, useState} from "react";
import moment from "moment";
import {
    format,
    addMonths,
    subMonths,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    addDays,
    isSaturday,
    isSunday,
} from "date-fns";
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";
import {
    faCheck,
    faCircleCheck,
    faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
import {FaUser} from "react-icons/fa";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "react-hook-form";
import {AttendanceDateArrayCalendar, CalendarApi} from "../../apis/Calendar";
import Swal from "sweetalert2";
import {useAuthDispatch, useAuthState} from "../../context/Auth";
import {io} from "socket.io-client";
import axiosInstance from "../../apis/Axios";

const CalendarComponent = () => {
    const week = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
    const [currentDate, setCurrentDate] = useState(new Date());
    const [marks, setMarks] = useState([]);
    const [latestAttendances, setLatestAttendances] = useState([]);
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const {user} = useAuthState();

    const fetchMarks = async (month) => {
        try {
            const data = await AttendanceDateArrayCalendar(user.id, month);
            console.log(`fetchMarks: ${data}`);
            setMarks(data);
        } catch (error) {
            console.error("Error fetching marks:", error);
        }
    };

    const fetchLatestAttendances = async () => {
        let socketUrl;
        if (process.env.NODE_ENV === "production") {
            socketUrl = "wss://api.시즌프리.com:8000/attendance";
        } else {
            socketUrl = "http://localhost:8000/attendance";
        }

        const socket = io(socketUrl, {
            withCredentials: true
        });
        console.log("소켓 접속중..");

        socket.emit('requestLiveAttendances');

        socket.on('updateLiveAttendances', (data) => {
            setLatestAttendances(data);
        });

        return () => {
            socket.off('updateLiveAttendances');
            socket.close();
        };
    };

    useEffect(() => {
        fetchLatestAttendances();
    }, []);

    useEffect(() => {
        const month = format(currentDate, "MM");
        fetchMarks(month);
    }, [currentDate]);

    useEffect(() => {
        renderCalendar();
    }, [marks]);

    const renderCalendar = () => {
        const days = [];
        let startDay = startDate;

        while (startDay <= endDate) {
            const weekDays = [];
            for (let i = 0; i < 7; i++) {
                const formattedDate = format(startDay, "yyyy-MM-dd");
                const isMarked = marks.includes(formattedDate);
                weekDays.push(
                    <Day key={startDay}>
                        <DaySpan
                            style={{
                                color: format(currentDate, "MM") !== format(startDay, "MM") ? "#ddd" : isSunday(startDay) ? "red" : isSaturday(startDay) ? "blue" : "#000",
                            }}
                        >
                            {format(startDay, "d")} {isMarked &&
                            <FontAwesomeIcon icon={faCheck} style={{color: "red"}}/>}
                        </DaySpan>
                    </Day>
                );
                startDay = addDays(startDay, 1);
            }
            days.push(<DayBox key={startDay}>{weekDays}</DayBox>);
        }

        return days;
    };

    const prevMonth = () => {
        setCurrentDate(subMonths(currentDate, 1));
    };

    const nextMonth = () => {
        setCurrentDate(addMonths(currentDate, 1));
    };

    const {register, handleSubmit, formState: {errors}} = useForm();
    const dispatch = useAuthDispatch();

    const onSubmit = async (formData) => {
        const {comment} = formData;
        try {
            await CalendarApi(user.id, comment);
            const month = format(currentDate, "MM");
            await fetchMarks(month);
            // 출석체크 후 최신 출석 데이터를 다시 가져옴
            await fetchLatestAttendances();
            const pointsResponse = await axiosInstance.get(`/user/points/${user.id}`);
            dispatch({
                type: 'UPDATE_POINTS',
                payload: { points: pointsResponse.data.points },
            });
        } catch (error) {
            console.error("체크인 중 에러 발생:", error);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <CalendarContainer>
                <Header>
                    <TodayText>{moment(currentDate).format("YYYY/MM/DD")}</TodayText>
                    <MonthButton>
                        <Button onClick={prevMonth}>
                            <AiOutlineLeft size={15} color="#000"/>
                        </Button>
                        <Title>
                            {format(currentDate, "yyyy")}년 {format(currentDate, "M")}월
                        </Title>
                        <Button onClick={nextMonth}>
                            <AiOutlineRight size={15} color="#000"/>
                        </Button>
                    </MonthButton>
                </Header>
                <CalendarBox>
                    <WeekLayout>{week.map((item, index) => <Week key={index}>{item}</Week>)}</WeekLayout>
                    <DayLayout>{renderCalendar()}</DayLayout>
                </CalendarBox>
                <NoticeRow>
                    <FontAwesomeIcon icon={faCircleCheck} style={{height: "35px"}}/>
                    <CheckNotice>출석 시 100P 지급됩니다.</CheckNotice>
                </NoticeRow>
                {latestAttendances.map((attendance) => (
                    <NoticeRow key={attendance.id}>
                        <div>
                            {attendance.imageUrl ? (
                                <img
                                    src={attendance.imageUrl}
                                    alt="Profile"
                                    style={{height: "35px", width: "35px", borderRadius: "50%"}}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faCircleUser} style={{height: "35px"}}/>
                            )}
                            <div
                                style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                }}
                            >
                                {attendance.nickname}
                            </div>
                        </div>
                        <Notice>
                            <div className="noticeHeader">
                                <div>{moment(attendance.checkTime).format("HH:mm")}</div>
                                <div>
                                    <span>100P</span> 보상
                                </div>
                            </div>
                            <div className="noticeContent">{attendance.comment}</div>
                        </Notice>
                    </NoticeRow>
                ))}
                <AttendanceBox>
                    <InputComponent
                        placeholder="오늘의 한마디를 남겨주세요(10자 이상)"
                        {...register("comment", {required: true, minLength: 10})}
                    />
                    {errors.comment && errors.comment.type === "required" &&
                        <div style={{color: "red"}}>한마디를 입력해주세요.</div>}
                    {errors.comment && errors.comment.type === "minLength" &&
                        <div style={{color: "red"}}>한마디는 최소 10자 이상이어야 합니다.</div>}
                </AttendanceBox>
                <SubmitButton>출석체크하기</SubmitButton>
            </CalendarContainer>
        </form>
    );
};

const CalendarContainer = styled.div``;

const MonthButton = styled.div`
    display: flex;
`;

const TodayText = styled.div`
    font-size: 25px;
    font-weight: bold;
    margin-left: 20px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Title = styled.div`
    font-size: 20px;
    color: #000;
    font-weight: 700;
    display: flex;
    align-items: center;
`;

const Button = styled.div`
    margin: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CalendarBox = styled.div`
    margin-top: 40px;
`;

// 포인트 지급 안내 및 실시간 요소
const NoticeRow = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
`;
const CheckNotice = styled.div`
    width: 100%;
    min-height: 35px;
    padding: 0px 10px;
    text-align: center;
    font-weight: bold;
    background-color: gray;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Notice = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-height: 75px;
    padding: 5px 10px;
    text-align: center;
    font-weight: bold;
    background-color: gray;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .noticeHeader {
        width: 100%;
        display: flex;
        gap: 10px;
        font-size: 12px;
        font-weight: normal;

        & span {
            color: blue;
        }
    }

    & .noticeContent {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        gap: 10px;
        font-size: 18px;
        font-weight: normal;
    }
`;
const AttendanceBox = styled.div`
    margin: 20px 0;
`;

const WeekLayout = styled.div`
    display: flex;
`;

const Week = styled.div`
    width: 14.28%;
    color: white;
    background-color: #282c34;
    font-weight: bold;
    display: flex;
    justify-content: center;
    border: 1px solid black;
    //border-radius: 10px;
    padding: 10px;
`;

const DayLayout = styled.div`
    width: 100%;
    //margin-top: 10px;
`;

const DayBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Day = styled.div`
    width: 14.28%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
`;

const DaySpan = styled.span`
    padding: 10px;
    border-radius: 50%;
    position: relative;
    font-weight: 700;
`;

const InputComponent = styled.input`
    width: 100%;
    padding: 8px;
    margin: 10px 0;
`;

const SubmitButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 5px;
`;

export default CalendarComponent;

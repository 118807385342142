import React, {useState} from "react";
import {useForm} from "react-hook-form";
import styled from "styled-components";
import {SendEmail, ValidateEmail} from "../../apis/User";

const FindId = () => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [sendEmail, setSendEmail] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const [userId, setUserId] = useState(null);

    const handleSendEmail = async (data) => {
        await SendEmail(data.email, setSendEmail);
        setVerificationSent(true);
    };

    const handleVerifyCode = async (data) => {
        await ValidateEmail(data.email, data.otp, setVerificationSent, setUserId);
    };

    return (
        <FormContainer>
            <form onSubmit={handleSubmit(sendEmail ? handleVerifyCode : handleSendEmail)}
                  style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                <InputComponent
                    {...register("email", {
                        required: "이메일은 필수 입력 항목입니다.",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "유효한 이메일 주소를 입력해주세요."
                        }
                    })}
                    placeholder="가입하실 때 입력하신 이메일 주소를 입력 해 주세요."
                    disabled={sendEmail}
                />
                {errors.email && <ErrorText>{errors.email.message}</ErrorText>}

                {sendEmail && (
                    <>
                        <InputComponent
                            {...register("otp", {required: "인증번호를 입력해주세요."})}
                            placeholder="인증번호를 입력하세요."
                        />
                        {errors.otp && <ErrorText>{errors.otp.message}</ErrorText>}
                    </>
                )}

                <SubmitButton type="submit">{sendEmail ? "인증하기" : "인증번호 전송하기"}</SubmitButton>
            </form>

            {userId && (
                <ResultContainer>
                    <p>고객님의 아이디는 <strong>{userId}</strong> 입니다.</p>
                </ResultContainer>
            )}
        </FormContainer>
    );
}

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const InputComponent = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const ErrorText = styled.span`
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
`;

const SubmitButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

const ResultContainer = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`;

export default FindId;

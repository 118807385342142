import React, { useState } from "react";
import { useAuthState } from "../../context/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Popup from "./Popup";
import ThumbsUp from "../../container/bbs/ThumbsUp";
import styled from "styled-components";
import Swal from "sweetalert2";
import axiosInstance from "../../apis/Axios";

const TableRow = ({
                      index,
                      id,
                      title,
                      nickname,
                      writeDate,
                      postColor,
                      watch,
                      gameKey,
                      postType: initialPostType,
                      onPostUpdated
                  }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [postBackColor, setPostBackColor] = useState(postColor);
    const [postType, setPostType] = useState(initialPostType);
    const { user } = useAuthState();

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const isAdmin = user?.role === "ADMIN";

    const handleColorChange = (newColor) => {
        setPostBackColor(newColor);
        setPostType("RECOMMEND");
        onPostUpdated(); // 리스트 업데이트 호출
    };

    const handleRecommendClick = () => {
        if (postType === "RECOMMEND") {
            Swal.fire({
                title: "추천을 취소하시겠습니까?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "네",
                cancelButtonText: "아니오",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await axiosInstance.delete(`/admin/thumbs-up/${id}`);
                        Swal.fire("취소되었습니다.", "", "success");
                        setPostType("GENERAL");
                        setPostBackColor(null);
                        onPostUpdated(); // 리스트 업데이트 호출
                    } catch (error) {
                        Swal.fire("오류가 발생했습니다.", "", "error");
                    }
                }
            });
        } else {
            setIsPopupOpen(true);
        }
    };

    return (
        <>
            <StyledTr postColor={postBackColor}>
                <Td>{index}</Td>
                <Td>
                    {(postType === "RECOMMEND" || isAdmin) && (
                        <HighlightButton
                            onClick={isAdmin ? handleRecommendClick : null}
                            disabled={!isAdmin && postType !== "RECOMMEND"}
                        >
                            <FontAwesomeIcon icon={faThumbsUp} /> 추천
                        </HighlightButton>
                    )}
                </Td>
                <Td>
                    <Link to={`/${gameKey}/${id}`}>{title}</Link>
                </Td>
                <Td>{nickname}</Td>
                <Td>{writeDate}</Td>
                <Td>{watch}</Td>
            </StyledTr>
            {isPopupOpen && (
                <Popup isOpen={isPopupOpen} title="게시글 추천" onClose={handleClosePopup}>
                    <ThumbsUp postId={id} onClose={handleClosePopup} onColorChange={handleColorChange} />
                </Popup>
            )}
        </>
    );
};

const StyledTr = styled.tr`
    background-color: ${(props) => props.postColor || "transparent"};
`;

const HighlightButton = styled.button`
    width: 100px;
    height: 40px;
    font-size: 20px;
    background-color: greenyellow;
    color: lightslategray;
    font-weight: bold;
    border-radius: 5px;
    border: 0;
    box-shadow: 2px 2px;
`;

const Td = styled.td`
    padding: 8px;
    text-align: center;
`;

export default TableRow;

import styled from "styled-components";
import React, { useState } from "react";

const BannerAdd = ({ onSubmit, onClose }) => {
    const [imageFile, setImageFile] = useState(null);
    const [clickUrl, setClickUrl] = useState('');

    const handleImageFileChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleClickUrlChange = (e) => {
        setClickUrl(e.target.value);
    };

    const handleSubmit = () => {
        onSubmit(imageFile, clickUrl);
        onClose();
    };

    return (
        <PopupContent>
            <form>
                <label>
                    클릭 시 이동할 URL을 입력하세요. 없으면 입력하지 않아도 됩니다.
                    <InputComponent
                        type="text"
                        placeholder="ex)https://시즌프리.com"
                        value={clickUrl}
                        onChange={handleClickUrlChange}
                    />
                </label>
                <label>
                    이미지 파일:
                    <InputComponent type="file" onChange={handleImageFileChange} />
                </label>
            </form>
            <ButtonContainer>
                <SubmitButton onClick={handleSubmit}>전송하기</SubmitButton>
            </ButtonContainer>
        </PopupContent>
    );
};

const InputComponent = styled.input`
    width: 95%;
    margin: 10px;
    padding: 8px;
`;

const PopupContent = styled.div`
    margin-top: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const SubmitButton = styled.button`
    background-color: #4CAF50;
    width: 100%;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
`;

export default BannerAdd;
